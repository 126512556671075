import EventLogger from "server/event-logger"
import type { APIDocument } from "models/Document"

export type DocumentStatus = ReturnType<typeof documentStatus>

export default function documentStatus(
  doc: Pick<
    APIDocument,
    | "conversion_finished_at"
    | "conversion_requested_at"
    | "error_message"
    | "pdf_file_key"
    | "thumbnails"
    | "thumbnails_finished_at"
    | "uuid"
    | "num_pages"
  >
) {
  const {
    conversion_finished_at,
    conversion_requested_at,
    error_message,
    pdf_file_key,
    thumbnails,
    thumbnails_finished_at,
    uuid,
    num_pages,
  } = doc

  if (thumbnails && thumbnails_finished_at && num_pages && !error_message) {
    return "CONVERSION_COMPLETE"
  }

  if (thumbnails && thumbnails_finished_at && !num_pages) {
    EventLogger.write("DATA_EVENT", {
      event: "empty-num-pages-with-thumbnails",
      docId: uuid,
      numPages: num_pages,
      thumbnailsLength: thumbnails?.length || 0,
    })

    return "FAILED_CONVERTING_DOCUMENT"
  }

  if (conversion_finished_at && error_message) {
    return "FAILED_GENERATING_THUMBNAILS"
  }

  if (pdf_file_key && conversion_finished_at && !error_message) {
    return "SUCCESS_CONVERTING_DOCUMENT"
  }

  if (conversion_requested_at && error_message && uuid) {
    return "FAILED_CONVERTING_DOCUMENT"
  }

  if (!error_message && uuid) {
    return "SUCCESS_CREATING_DOCUMENT"
  }

  return "IN_PROGRESS"
}
