import { reduceIntoKeyByValue } from "helpers/array"

import withAuthRepAssignments from "features/transaction/id/auth-rep-assignments"

import { addRootEntitiesToAuthRep } from "helpers/mappings/findEntityForAuthRep"
import { buildAuthRepTreeForEntity } from "helpers/mappings/buildAuthRepTreeForEntity"
import { groupByKey } from "helpers/array"
import { Item, TransactionDashboardItem } from "models/Item"
import { Assignment } from "models/Assignment"
import { Signatory } from "models/Signatory"
import { Page, PageWithItemsAndAssignments } from "models/Pages"

export type FullAssignment = Assignment & {
  page: Page
  item: Item
}

export function itemPageAssignments(
  items: Item[] | undefined = [],
  assignments: Assignment[] | undefined = []
): FullAssignment[] {
  if (items.length === 0 || assignments.length === 0) return []

  let assignmentsById = reduceIntoKeyByValue(assignments)
  let itemsById = reduceIntoKeyByValue(items)

  return items
    .reduce<Page[]>((pages, item) => [...pages, ...item.pages], [])
    .reduce<{ id: string; page: Page }[]>(
      (assignments, page) => [
        ...assignments,
        ...(page.assignments || []).map((uuid) => ({ id: uuid, page })),
      ],
      []
    )
    .map((pageAssignment) => {
      let assignment = assignmentsById[pageAssignment.id]

      if (assignment) {
        return {
          ...pageAssignment, // has page data
          ...assignment,
          ...{ item: itemsById[assignment?.itemId] },
        }
      }

      return null
    })
    .filter(
      // NOTE assignment has to be included in a page
      (maybeAssignment): maybeAssignment is FullAssignment =>
        Boolean(maybeAssignment && maybeAssignment.item && maybeAssignment.page)
    )
}

export function useTransactionSignatoriesWithAssignments(
  items: TransactionDashboardItem[] = [],
  signatories: Signatory[] = []
) {
  const itemsById = reduceIntoKeyByValue(items)

  const assignmentsBySignatoryId = groupByKey(
    items
      .reduce<PageWithItemsAndAssignments[]>(
        (pages, item) => [...pages, ...item.pages],
        []
      )
      .reduce<Assignment[]>(
        (assignments, page) => [...assignments, ...(page.assignments || [])],
        []
      )
      .map((assignment) => ({
        ...assignment,
        item: itemsById[assignment.itemId],
        signatory: signatories.find(
          (signatory) => signatory.id === assignment.signatory.id
        ),
        signatoryId: assignment.authRep || assignment.signatory.id,
      })),
    "signatoryId"
  )

  const assignmentSignatoryIds = Object.keys(assignmentsBySignatoryId)

  const transactionSignatories = (
    signatories
      ?.map((signatory, _, signatories) =>
        buildAuthRepTreeForEntity(signatory, signatories)
      )
      .map((signatory, _, signatories) =>
        addRootEntitiesToAuthRep(signatory, signatories, (entity) =>
          assignmentSignatoryIds.includes(entity?.id ?? "")
        )
      ) || []
  )
    .filter((signatory) => assignmentSignatoryIds.includes(signatory.id))
    .map((signatory) => ({
      ...signatory,
      assignments: [
        ...(assignmentsBySignatoryId[signatory.id] || []),
        ...withAuthRepAssignments(signatory, assignmentsBySignatoryId),
      ],
    }))

  return transactionSignatories
}
