export type APIClosingBinderDownload = {
  name: string
  nickname: string
  last_build_date: string
  notification_uuid: string
  downloads: {
    pdf_file: string
    zip_file: string
    docx_zip_file: string
  }
}

// TODO-TS: In app code, the fields were not camelCased
// so leaving them unchanged here
export type ClosingBinderDownload = {
  name: string
  nickname: string
  last_build_date: string
  notification_uuid: string
  downloads: {
    pdf_file: string
    zip_file: string
    docx_zip_file: string
  }
}

export const decode = (json: APIClosingBinderDownload): ClosingBinderDownload =>
  json
