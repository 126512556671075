import React from "react"
import { State, ExternalDispatch } from "./reducer"

const UploadQueueContext = React.createContext<{
  state: State
  dispatch: ExternalDispatch
}>({
  state: {},
  dispatch: () => {},
})

export function useUploadQueueContext() {
  return React.useContext(UploadQueueContext)
}

export default UploadQueueContext
