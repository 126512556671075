import { useCurrentUser } from "features/auth/withAuth"

import ComputerIcon from "icons/computer.svg"
import NetDocsIcon from "icons/netdocs.svg"
import IManageIcon from "icons/imanage.svg"
import { Button, Position } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

type SelectFileButtonsProps = {
  onOpenDMS: () => void
  openFileDialog: () => void
  showDragText?: boolean
}

export default function SelectFileButtons({
  onOpenDMS,
  openFileDialog,
  showDragText = false,
}: SelectFileButtonsProps) {
  const { currentUser } = useCurrentUser({ fetchUser: false })

  let buttonId =
    currentUser?.dmsType === "NET_DOCS"
      ? "upload-documents-button-netdocs"
      : currentUser?.dmsType === "IMANAGE"
        ? "upload-documents-button-imanage"
        : "upload-documents-button-dms"

  return (
    <div className="flex flex-shrink-0 gap-4">
      <Tooltip2
        content="Upload from computer"
        position={Position.TOP}
        hoverOpenDelay={500}
      >
        <Button
          onClick={openFileDialog}
          className="!bg-white border rounded py-2 px-4 flex items-center !text-blue-6"
        >
          <ComputerIcon className="w-6 h-6 inline-block" viewBox="0 0 16 16" />
          <span className="hidden xl:inline-block ml-1 leading-6">Browse</span>
        </Button>
      </Tooltip2>
      {currentUser?.hasDMS ? (
        <Tooltip2
          content={`Upload from ${
            currentUser?.dmsType === "NET_DOCS" ? "NetDocuments" : "iManage"
          }`}
          position={Position.TOP}
          hoverOpenDelay={500}
        >
          <Button
            id={buttonId}
            onClick={onOpenDMS}
            className="!bg-white border rounded py-2 px-4 flex items-center !text-blue-6"
          >
            {currentUser?.dmsType === "NET_DOCS" ? (
              <NetDocsIcon className="w-6 h-6 inline-block" />
            ) : (
              <IManageIcon className="w-8 h-6 inline-block" />
            )}
            <span className="hidden xl:inline-block ml-1 leading-6">
              {currentUser?.dmsType === "NET_DOCS" ? "NetDocuments" : "iManage"}
            </span>
          </Button>
        </Tooltip2>
      ) : null}
      {showDragText ? (
        <p className="leading-10 hidden md:block">or drag and drop to upload</p>
      ) : null}
    </div>
  )
}
