import { Document } from "models/Document"
import { FileDoc } from "models/FileDoc"

export type State = {
  [transactionId: string]: {
    acceptedFiles?: any[]
    files?: FileDoc[]
    pendingFileVersions?: string[]
  }
}

export type Action =
  | { type: "ADD_FILE"; file: FileDoc; transactionId: string }
  | { type: "ADD_FILES"; files: FileDoc[]; transactionId: string }
  | { type: "CLEAR"; transactionId: string }
  | { type: "BULK_UPDATE_DOCUMENTS"; properties: any; transactionId: string }
  | { type: "REMOVE_DOCUMENTS"; documentIds: string[]; transactionId: string }
  | { type: "REPLACE_FILE"; file: FileDoc; transactionId: string }
  | { type: "UPDATE_DOCUMENTS"; documents: FileDoc[]; transactionId: string }
  | { type: "MERGE_DOCUMENTS"; documents: FileDoc[]; transactionId: string }
  | { type: "UPDATE_FILE"; file: FileDoc; transactionId: string }
  | { type: "UPDATE_FILE_STATUS"; file: FileDoc; transactionId: string }
  | { type: "UPLOAD_SUCCESS"; data: any; transactionId: string }

export type ExternalDispatch = (action: Action) => void

export default function Reducer(state: State, action: Action): State {
  switch (action.type) {
    case "ADD_FILE":
      return {
        ...state,
        [action.transactionId]: {
          ...state[action.transactionId],
          files: [
            ...(state[action.transactionId]?.files || []),
            {
              ...action.file,
              uploadOrder: state[action.transactionId]?.files?.length || 0,
            },
          ],
        },
      }
    case "ADD_FILES": {
      return {
        ...state,
        [action.transactionId]: {
          ...state[action.transactionId],
          files: [
            ...(state[action.transactionId]?.files || []),
            ...action.files.map((file, index) => {
              return {
                ...file,
                uploadOrder:
                  (state[action.transactionId]?.files?.length || 0) + 1 + index,
              }
            }),
          ],
        },
      }
    }
    case "CLEAR":
      return {
        ...state,
        [action.transactionId]: {
          acceptedFiles: [],
          files: [],
          pendingFileVersions: [],
        },
      }
    case "BULK_UPDATE_DOCUMENTS":
      return {
        ...state,
        [action.transactionId]: {
          ...state[action.transactionId],
          files: state[action.transactionId]?.files?.map((file) => ({
            ...file,
            ...action.properties,
          })),
        },
      }
    case "REMOVE_DOCUMENTS": {
      return {
        ...state,
        [action.transactionId]: {
          ...state[action.transactionId],
          files: state[action.transactionId]?.files?.map((file) => {
            if (action.documentIds.includes(file.id)) {
              return { ...file, status: "REMOVED" }
            }
            return file
          }),
        },
      }
    }
    case "REPLACE_FILE": {
      return {
        ...state,
        [action.transactionId]: {
          ...state[action.transactionId],
          files: state[action.transactionId]?.files?.map((file) => {
            if (file.id === action.file.id) {
              return {
                ...action.file,
                id: action.file.uuid || action.file.id, // uuid from API takes precedent
                name: file.name,
                signingType: file.signingType,
              }
            }
            return file
          }),
        },
      }
    }
    case "UPDATE_DOCUMENTS": {
      return {
        ...state,
        [action.transactionId]: {
          ...state[action.transactionId],
          files: state[action.transactionId]?.files?.map((file) => {
            const doc = action.documents.find((d) => d.id === file.id)

            if (file.id === doc?.id) {
              return { ...file, ...doc }
            }

            return file
          }),
        },
      }
    }
    case "MERGE_DOCUMENTS": {
      const isInitial = state[action.transactionId]?.files?.length === 0

      const localFiles = state[action.transactionId]?.files?.map((file) => {
        if (file.status === "REMOVED") {
          // ignore any API changes to removed files
          return file
        }

        const doc = action.documents.find((d) => d.id === file.id)
        if (doc) {
          return {
            ...file,
            ...doc,
            name: file.name,
            signingType: file.signingType, // keep any local changes to be synced later
            isSelected:
              file.isSelected ||
              (file.status !== doc.status &&
                doc.status === "CONVERSION_COMPLETE"),
          }
        }

        return file
      })

      const newDocuments = action.documents
        .filter(
          (doc) =>
            !state[action.transactionId]?.files?.find(
              (file) => file.id === doc.id
            )
        )
        .map((doc) => ({ ...doc, isSelected: isInitial }))

      const files = [...(localFiles || []), ...newDocuments]

      return {
        ...state,
        [action.transactionId]: {
          ...state[action.transactionId],
          files: files,
        },
      }
    }
    case "UPDATE_FILE": {
      return {
        ...state,
        [action.transactionId]: {
          ...state[action.transactionId],
          files: state[action.transactionId]?.files?.map((file) => {
            if (file.id === action.file.id) {
              return { ...file, ...action.file }
            }
            return file
          }),
        },
      }
    }
    case "UPDATE_FILE_STATUS": {
      return {
        ...state,
        [action.transactionId]: {
          ...state[action.transactionId],
          files: state[action.transactionId]?.files?.map((file) => {
            if (file.id === action.file.id) {
              return { ...file, status: action.file.status }
            }
            return file
          }),
        },
      }
    }
    case "UPLOAD_SUCCESS":
      return {
        ...state,
        [action.transactionId]: {
          ...state[action.transactionId],
          acceptedFiles: state[action.transactionId]?.acceptedFiles?.map(
            (file) => {
              if (action.data.ids.includes(file.fvId)) {
                return {
                  ...file,
                  uploading: false,
                }
              }
              return file
            }
          ),
          pendingFileVersions: state[
            action.transactionId
          ]?.pendingFileVersions?.filter((fv) => !action.data.ids.includes(fv)),
        },
      }

    default:
      return state
  }
}

export function documentsToFileDocs(documents: Document[]): FileDoc[] {
  return documents.map((doc) => ({
    id: doc.id,
    name: doc.name,
    numPages: doc.numPages,
    uploadOrder: doc.uploadOrder ?? 0,
    signingType: doc.signingType,
    status: doc.status || "QUEUED", // default to queued if not provided
  }))
}
