import { useAPI } from "lib/api"
import { getDocuments } from "helpers/api"
import { decode as decodeDocument } from "models/Document"

export function useQueuedDocuments({
  pollInterval: refetchInterval,
  transactionId,
  enabled,
}: {
  pollInterval?: number
  transactionId?: string
  enabled?: boolean
}) {
  let result = useAPI(
    [transactionId, "documents", { inQueue: true }],
    () => getDocuments(transactionId, true),
    {
      refetchInterval,
      select: (data) => data.map(decodeDocument),
      enabled: enabled,
    }
  )

  return result
}
