const WORDS = {
  copy: { plural: "copies", singular: "copy" },
  day: { plural: "days", singular: "day" },
  document: { plural: "documents", singular: "document" },
  download: { plural: "downloads", singular: "download" },
  file: { plural: "files", singular: "file" },
  group: { plural: "groups", singular: "group" },
  packet: { plural: "packets", singular: "packet" },
  recipient: { plural: "recipients", singular: "recipient" },
  // adding both singular and plural to be able to go either way based on a lookup
  entities: { plural: "entities", singular: "entity" },
  entity: { plural: "entities", singular: "entity" },
  individuals: { plural: "individuals", singular: "individual" },
  signatories: { plural: "signatories", singular: "signatory" },
  signatory: { plural: "signatories", singular: "signatory" },
  signingGroup: { plural: "signing groups", singular: "signing group" },
  signingGroups: { plural: "signing groups", singular: "signing group" },
} as const

type TWords = keyof typeof WORDS

const NullWord = (word: TWords) => ({
  plural: word,
  singular: word,
})

class PluralizedWords {
  static for(word: TWords) {
    return WORDS[word] || NullWord(word)
  }
}
export default function pluralize(word: TWords, amount: number) {
  return PluralizedWords.for(word)[amount === 1 ? "singular" : "plural"]
}
