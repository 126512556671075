import { ToasterInstance } from "@blueprintjs/core"
import { User } from "models/User"
import { createContext, Dispatch, SetStateAction } from "react"

export const TransitionContext = createContext("transition")
export const PageRatioContext = createContext("page-ratio")
export const ToasterContext = createContext<{
  toaster: undefined | ToasterInstance
}>({
  toaster: undefined,
})

// Context for Auth, to share Auth state throughout application.
type AuthContextType = {
  user: undefined | User
  logoutAsync: undefined | ((reason?: string | object) => Promise<void>)
  refreshUser: undefined | (() => Promise<void>)
}
export const AuthContext = createContext<AuthContextType>({
  user: undefined,
  logoutAsync: undefined,
  refreshUser: undefined,
})

type AppConfigContextType = {
  config: Window["config"]
  setConfig: Dispatch<SetStateAction<Window["config"] | null>> | null
  fetchFeatureStatusAndUpdateConfig: () => void
}

export const AppConfigContext = createContext<AppConfigContextType>({
  config: {},
  setConfig: () => null,
  fetchFeatureStatusAndUpdateConfig: () => null,
})
