import * as React from "react"

import { Button } from "@blueprintjs/core"

type DocumentTrayProps = {
  title?: string
  collapsedTitle?: string
  documentAmount?: number
  isOpen?: boolean
  onClose?: () => void
  isCollapsed?: boolean
  setCollapsed?: () => void
  placement?: string
  collapsedPlacement?: string
  children?: React.ReactNode
}

export default function DocumentTray({
  title = "",
  collapsedTitle = "",
  documentAmount = 0,
  isOpen = false,
  onClose = () => null,
  placement = "right-0 bottom-0",
  collapsedPlacement = "right-0 bottom-0",
  children,
}: DocumentTrayProps) {
  const [isTrayCollapsed, setIsTrayCollapsed] = React.useState(false)

  return (
    <>
      {isOpen && (
        <>
          {isTrayCollapsed ? (
            <div
              onClick={() => setIsTrayCollapsed(!isTrayCollapsed)}
              className={`absolute ${collapsedPlacement} cursor-pointer rounded-full bg-blue-6 p-2 shadow-lg transition-all duration-100 hover:bg-blue-6/70`}
            >
              <div className="flex items-center">
                <img src="/static/sa-logo.svg" className="w-8" />
                <div className="ml-2 text-sm text-white">
                  {collapsedTitle}
                  <span className="ml-1">({documentAmount})</span>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`absolute ${placement} w-[380px] rounded-sm border border-gray-300 bg-[#f5f8fa] p-3 shadow-2xl`}
            >
              <div className="flex items-center">
                <div className="text-2xl">{title}</div>
                <Button
                  icon="chevron-down"
                  minimal
                  className="ml-auto"
                  onClick={() => setIsTrayCollapsed(!isTrayCollapsed)}
                />
              </div>
              <div className="my-3 flex max-h-[250px] flex-col-reverse overflow-auto">
                {children}
              </div>
              <div className="flex w-full">
                <Button
                  text="Done"
                  className="ml-auto"
                  intent="primary"
                  onClick={onClose}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}
