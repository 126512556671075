import userSupportService from "./services/support"
import whatsNewService from "./services/whats-new"
import errorReportingService from "./services/error-reporting"

export const SERVICES = {
  ERROR_REPORTING: errorReportingService,
  HIDE_SUPPORT: userSupportService,
  WHATS_NEW_FLOW_ID: whatsNewService,
}

const Noop: any = () => null

export default function loadServiceForConfig(
  key: keyof typeof SERVICES,
  value:
    | ReturnType<typeof errorReportingService>
    | ReturnType<typeof userSupportService>
    | ReturnType<typeof whatsNewService>
    | ReturnType<typeof Noop>
) {
  let service = SERVICES[key] || Noop

  service(value)
}
