export default async function loadScript(src: string) {
  let script = document.querySelector(`script[src="${src}"]`)

  if (script) return

  return new Promise((resolve: (v?: unknown) => void) => {
    let tag = document.createElement("script")
    tag.src = src
    tag.async = true
    tag.onload = () => resolve()
    document.body.appendChild(tag)
  })
}
