import * as React from "react"

import { Icon, Intent } from "@blueprintjs/core"

import Layout from "../../../layouts/external"

type ErrorPageProps = {
  error?: Record<string, any>
}

export default function ErrorPage(props: ErrorPageProps) {
  console.log(props)
  return (
    <Layout>
      <div className="flex h-full w-full pt-8">
        <div className="m-auto flex w-1/3 min-w-[500px] flex-col rounded bg-white p-5 text-center">
          <Icon
            icon="warning-sign"
            size={96}
            className="mx-auto"
            intent={Intent.DANGER}
          />
          <h3 className="mb-3 mt-5 font-normal tracking-wider">
            Something went wrong
          </h3>
          <p className="mb-3 px-8">
            Please refresh the page and try again. If the problem persists,
            please reach out to <i>support@simplyagree.com</i>.
          </p>
          {props.error?.message ? (
            <div className="max-h-32 overflow-auto rounded bg-red-100 p-3 text-left">
              <p>{props.error.message}</p>
              <pre>{props.error.stack}</pre>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  )
}
