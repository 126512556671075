class EventLogger {
  write(
    eventType,
    attributes,
    output = console,
    getDateTime = () => new Date().toISOString()
  ) {
    if (!eventType || !attributes) return

    output.log(
      `[${eventType}] ${new URLSearchParams({
        ...attributes,
        dateTime: getDateTime(),
      })
        .toString()
        .replaceAll("&", " ")
        .replaceAll("%3A", ":")}`
    )
  }
}

module.exports = new EventLogger()
