import {
  SignatoryDescriptionOptions,
  SignatoryFormatOptions,
  SignatoryInstapagesConfig,
  SignatoryTitleOptions,
} from "models/Signatory"

const LINE_STYLE_TYPES = ["solid", "dotted", "dashed"] as const
export type LineStyleType = (typeof LINE_STYLE_TYPES)[number]

export type TitleType = SignatoryInstapagesConfig

export type SignatoryDescriptionType = {
  format: SignatoryDescriptionOptions
  isEnabled: boolean
}

const TEXT_TRANSFORM_TYPES = [
  "none",
  "uppercase",
  "lowercase",
  "capitalize",
] as const
export type TextTransformType = (typeof TEXT_TRANSFORM_TYPES)[number]

const DEFAULT_BLOCK_HEADER_STYLES: BlockStyleTypes = {
  fontSize: "12px",
  bold: true,
  italic: false,
  underline: false,
  textTransform: "uppercase",
  isEnabled: true,
}

const DEFAULT_BLOCK_STYLE: BlockStyleTypes = {
  fontSize: "12px",
  bold: false,
  italic: false,
  underline: false,
  textTransform: "none",
  isEnabled: true,
}

const DEFAULT_SIGNATORY_NAME_STYLE: BlockStyleTypes = {
  fontSize: "12px",
  bold: false,
  italic: false,
  underline: false,
  textTransform: "none",
  isEnabled: true,
}

const DEFAULT_TITLE_STYLE = {
  entity: SignatoryTitleOptions.ITS,
  format: SignatoryFormatOptions.STYLE1,
  individual: SignatoryTitleOptions.TITLE,
}

const DEFAULT_SIGNATORY_DESCRIPTION_STYLE = {
  format: SignatoryDescriptionOptions.STYLE1,
  isEnabled: true,
}

export const DEFAULT_BLOCK_STYLES: BlockStyles = {
  header: DEFAULT_BLOCK_HEADER_STYLES,
  signatoryName: DEFAULT_SIGNATORY_NAME_STYLE,
  signatoryDescription: DEFAULT_SIGNATORY_DESCRIPTION_STYLE,
  name: DEFAULT_BLOCK_STYLE,
  byLineLabels: DEFAULT_BLOCK_STYLE,
  title: DEFAULT_TITLE_STYLE,
  lineStyles: "solid",
}

type APIBlockStyleTypes = {
  font_size: string
  bold: boolean
  italic: boolean
  underline: boolean
  text_transform: TextTransformType
  is_enabled: boolean
}

export type BlockStyleTypes = {
  fontSize: string
  bold: boolean
  italic: boolean
  underline: boolean
  textTransform: TextTransformType
  isEnabled: boolean
}

export type APIBlockStyles = {
  header: APIBlockStyleTypes
  signatory_name: APIBlockStyleTypes
  signatory_description: SignatoryDescriptionType
  name: APIBlockStyleTypes
  by_line_labels: APIBlockStyleTypes
  title: TitleType
  line_styles: LineStyleType
}

export enum BlockStylesFieldNames {
  HEADER = "header",
  SIGNATORY_NAME = "signatoryName",
  SIGNATORY_DESCRIPTION = "signatoryDescription",
  NAME = "name",
  BY_LINE_LABELS = "byLineLabels",
  TITLE = "title",
  LINE_STYLES = "lineStyles",
}

// Every kind: "signature" block has a block_styles object which will usually
// be set on the InstPage as a whole, but can be overridden on a per-block basis
// in a future scenario / feature.
export type BlockStyles = {
  header: BlockStyleTypes
  signatoryName: BlockStyleTypes
  signatoryDescription: SignatoryDescriptionType
  name: BlockStyleTypes
  byLineLabels: BlockStyleTypes
  title: TitleType
  lineStyles: LineStyleType
}

const decodeBlockStylesFor = (
  data: Partial<APIBlockStyles>,
  kind: "header" | "signatory_name" | "name" | "by_line_labels"
) => {
  let kindData = data[kind]
  if (kindData)
    return {
      fontSize: kindData.font_size,
      bold: kindData.bold,
      italic: kindData.italic,
      underline: kindData.underline,
      textTransform: kindData.text_transform,
      isEnabled: kindData.is_enabled,
    }
}

export const decodeBlockStyles = (
  data: APIBlockStyles | undefined
): BlockStyles => {
  if (!data) return DEFAULT_BLOCK_STYLES
  return {
    header: decodeBlockStylesFor(data, "header") || DEFAULT_BLOCK_HEADER_STYLES,
    signatoryName:
      decodeBlockStylesFor(data, "signatory_name") || DEFAULT_BLOCK_STYLE,
    signatoryDescription:
      data.signatory_description || DEFAULT_SIGNATORY_DESCRIPTION_STYLE,
    name: decodeBlockStylesFor(data, "name") || DEFAULT_BLOCK_STYLE,
    byLineLabels:
      decodeBlockStylesFor(data, "by_line_labels") || DEFAULT_BLOCK_STYLE,
    title: data.title || DEFAULT_TITLE_STYLE,
    lineStyles: data.line_styles || "solid",
  }
}

export const encodeBlockStyle = (data: BlockStyleTypes) => {
  return {
    font_size: data.fontSize,
    bold: data.bold,
    italic: data.italic,
    underline: data.underline,
    text_transform: data.textTransform,
    is_enabled: data.isEnabled,
  }
}
