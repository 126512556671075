import * as React from "react"
const { useEffect, useRef } = React

type OnClickOutsideProps = {
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
  ignoreOverlays?: boolean
  ignoreOutsideElementClick?: boolean
  onClickOutside?: (e?: MouseEvent | TouchEvent) => void
}

export default function OnClickOutside({
  className,
  style,
  children,
  ignoreOverlays = false,
  ignoreOutsideElementClick = false,
  onClickOutside = () => {},
  ...props
}: OnClickOutsideProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function clickHandler(event: MouseEvent | TouchEvent) {
      if (ignoreOverlays) {
        const overlayPortal = document.querySelectorAll(
          ".bp4-portal, .bp4-portal, .sa-lightbox"
        )
        if (
          overlayPortal &&
          Array.from(overlayPortal).some((overlay) =>
            overlay.contains(event.target as Node)
          )
        ) {
          return
        }
      }

      if (ignoreOutsideElementClick) {
        const outsideElements = document.querySelectorAll(
          ".ignore-outside-element-click"
        )
        if (
          outsideElements &&
          Array.from(outsideElements).some(
            (element) => !element.contains(event.target as Node)
          )
        ) {
          return
        }
      }

      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }

      onClickOutside(event)
    }

    document.addEventListener("mousedown", clickHandler)
    document.addEventListener("touchstart", clickHandler)
    return () => {
      document.removeEventListener("mousedown", clickHandler)
      document.removeEventListener("touchstart", clickHandler)
    }
  }, [onClickOutside])

  return (
    <div {...props} ref={ref} className={className} style={style}>
      {children}
    </div>
  )
}
