type ExtraData = {
  target: Target
  s3_keys: S3Keys
  filename?: string
  filesize?: number
  actor_text?: string
}

type S3Keys = {
  pdf_file?: string
  zip_file?: string
  docx_zip_file?: string
}

type Target = {
  item_name?: string
  frontend_link?: string
  transaction_name?: string
  name?: string
  link?: string
  uuid?: string
}

type DownloadUrls = {
  pdf_file?: string
  zip_file?: string
  docx_zip_file?: string
}

export type APINotification = {
  cleared: boolean
  level: string
  notification_type: string
  title: string
  uuid: string
  read: boolean
  extra_data: ExtraData
  created_at: string
  download_urls: DownloadUrls
}

export type Notification = {
  cleared: boolean
  level: string
  title: string
  type: string
  id: string
  read: boolean
  extraData: ExtraData
  createdAt: string
  downloadUrls: DownloadUrls
}

export type UpdatableAPINotification = {
  read: boolean
}

export const decode = ({
  cleared,
  level,
  notification_type: type,
  title,
  uuid: id,
  read,
  extra_data: extraData,
  created_at: createdAt,
  download_urls: downloadUrls,
}: APINotification): Notification => ({
  createdAt,
  cleared,
  id,
  level,
  title,
  type,
  read,
  extraData,
  downloadUrls,
})
