import { Signatory } from "models/Signatory"

function findEntityIds(nestedEntities: Signatory[]): string[] {
  return nestedEntities.reduce<string[]>((ids, node) => {
    if (Array.isArray(node)) {
      return [...ids, ...findEntityIds(node)]
    }
    return [...ids, node.id]
  }, [])
}

export default function authRepAssignments<T>(
  authRep: Signatory & { entities?: Signatory[] },
  // TODO-TS: this isn't the full type but close enough for now
  assignmentsBySignatoryId: Record<string, T[]>
) {
  const entityIds = findEntityIds(authRep.entities || [])

  const initialAssignments: T[] = []
  const entityAssignments = entityIds.reduce((assignments, entityId) => {
    return [...assignments, ...(assignmentsBySignatoryId[entityId] || [])]
  }, initialAssignments)

  // return personal assignments as well??
  return entityAssignments
}
