import loadScript from "lib/load-script"

import { onSetEventTracker } from "app/user-engagement"

export default async function loadWhatsNew(
  flowIdValue: string | null | undefined
) {
  // We use the flowIdValue to determine if we should load appcues
  // however the actual flowId is referencing the "Whats New" feature.
  if (!flowIdValue) return

  await loadScript("//fast.appcues.com/53029.js")

  // Appcues SHOULD BE LOADED BEFORE WE SET THE EVENT TRACKER
  if (!window.Appcues) return

  onSetEventTracker(window.Appcues)
}
