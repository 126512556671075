type HeaderStyle =
  | "arabic"
  | "uppercase_roman"
  | "lowercase_roman"
  | "uppercase_alphabetic"
  | "lowercase_alphabetic"

export type APIClosingBinderFormatStructure = {
  index_style: "table_of_contents" | "outline"
  primary_header: HeaderStyle
  second_header: HeaderStyle
  third_header: HeaderStyle
  last_documents: HeaderStyle
  numbering: "tabs" | "pages" | "numbers" | "none"
  reset_tabs: boolean
}

export type ClosingBinderFormatStructure = {
  indexStyle: "table_of_contents" | "outline"
  primaryHeader: HeaderStyle
  secondHeader: HeaderStyle
  thirdHeader: HeaderStyle
  documentsHeader: HeaderStyle
  docNumbering: "tabs" | "pages" | "numbers" | "none"
  resetTabs: boolean
}

export const decode = ({
  index_style: indexStyle = "table_of_contents",
  primary_header: primaryHeader = "uppercase_roman",
  second_header: secondHeader = "uppercase_alphabetic",
  third_header: thirdHeader = "lowercase_roman",
  last_documents: documentsHeader = "lowercase_alphabetic",
  numbering: docNumbering = "tabs",
  reset_tabs: resetTabs = false,
}: APIClosingBinderFormatStructure): ClosingBinderFormatStructure => ({
  indexStyle,
  primaryHeader,
  secondHeader,
  thirdHeader,
  docNumbering,
  documentsHeader,
  resetTabs,
})
