import { Transaction } from "models/Transaction"
import { User } from "models/User"

const transactionExclude = ["test", "sample"]

let transactionName: Transaction["name"] | null = null

export function setTransaction(t: Transaction["name"] | null) {
  return (transactionName = t)
}

export function cAddUser(currentUser?: User) {
  if (!window.config?.CUSTIFY_ID || !currentUser) return

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${window.config?.CUSTIFY_ID}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: currentUser.full_name,
      user_id: currentUser.uuid,
      email: currentUser.email,
      company_id: currentUser.organization,
      signed_up_at: currentUser.experience,
      companies: [
        {
          company_id: currentUser.organization,
        },
      ],
      custom_attributes: {
        organization_name: currentUser.organization_name,
        role: currentUser.role,
      },
    }),
  }

  fetch("https://api.custify.com/people", requestOptions)
}

export function cAddOrganization(currentUser?: User) {
  if (!window.config?.CUSTIFY_ID || !currentUser) return

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${window.config.CUSTIFY_ID}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: currentUser.organization_name,
      company_id: currentUser.organization,
    }),
  }

  fetch("https://api.custify.com/company", requestOptions)
}

export function cEventTrack(
  currentUser?: User,
  eventName?: string,
  data?: any
) {
  if (!window.config?.CUSTIFY_ID || !currentUser || !eventName) return

  // if the event is a transaction creation and the name does not include "test" or "sample"
  if (
    eventName === "TRANSACTION_CREATED" &&
    !transactionExclude.some((v) => {
      return data.transactionName.toUpperCase().indexOf(v.toUpperCase()) !== -1
    })
  ) {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.config.CUSTIFY_ID}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: eventName,
        created_at: Date(),
        email: currentUser.email,
        company_id: currentUser.organization,
        metada: { data },
      }),
    }

    fetch("https://api.custify.com/event", requestOptions)
  }
  // if we have set the transaction above and the name does not include "test" or "sample"
  else if (
    transactionName &&
    !transactionExclude.some((v) => {
      return (
        (transactionName ?? "")?.toUpperCase().indexOf(v.toUpperCase()) >= 0
      )
    })
  ) {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.config.CUSTIFY_ID}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: eventName,
        created_at: Date(),
        email: currentUser.email,
        company_id: currentUser.organization,
        metada: { data },
      }),
    }

    fetch("https://api.custify.com/event", requestOptions)
  }
}
