type HttpOptions = {
  headers?: Record<string, string>
  method?: string
}

type HttpBody = string | ArrayBuffer | null | undefined

export const ExternalHttp = {
  get: <T>(url: string, config?: HttpOptions) =>
    Fetch<T>(url, null, { ...config, method: "GET" }),
  post: (url: string, body?: HttpBody, config?: HttpOptions) =>
    Fetch(url, body, { ...config, method: "POST" }),
  put: (url: string, body?: HttpBody, config?: HttpOptions) =>
    Fetch(url, body, { ...config, method: "PUT" }),
  patch: (url: string, body?: HttpBody, config?: HttpOptions) =>
    Fetch(url, body, { ...config, method: "PATCH" }),
  delete: (url: string, config?: HttpOptions) =>
    Fetch(url, null, { ...config, method: "DELETE" }),
}

async function Fetch<T>(
  url: string,
  // TODO-TS: can we remove undefined from body type?
  body: string | ArrayBuffer | null | undefined,
  { headers, ...options }: HttpOptions = {}
) {
  let contentType = headers?.["Content-Type"] || "application/json"
  let Accept = headers?.["Accept"] || "application/json"
  let acceptLanguage = headers?.["Accept-Language"] || "en-US,en;q=0.9"
  let config = {
    headers: {
      Accept,
      "Accept-Language": acceptLanguage,
      "Content-Type": contentType,
      ...headers,
    },
    ...options,
    body: !body
      ? undefined
      : headers?.["Content-Type"] &&
          headers["Content-Type"] !== "application/json" // IF custom Content-Type is set, do not JSON.stringify body
        ? body
        : JSON.stringify(body),
  }

  let response = await fetch(url, config)

  let responseHeaders = response.headers

  let data: T | string | { error: any; headers: Headers }

  let isError = response.status >= 400

  try {
    if (Accept === "application/json") {
      data = await response.json()
    } else {
      data = await response.text()
    }
  } catch (e) {
    // TODO Why are we setting error on data ??
    data = { error: e, headers: responseHeaders }
  }

  return { data, status: response.status, headers: responseHeaders, isError }
}
