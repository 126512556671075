export type APITransactionCustomField = {
  uuid: string
  transaction: string
  name: string
}

export type TransactionCustomField = {
  id?: string
  transactionId: string
  name: string
}

export type APICreateTransactionCustomField = Omit<
  APITransactionCustomField,
  "uuid"
>

export type CreateTransactionCustomField = Omit<TransactionCustomField, "id">

export type APIUpdatableTransactionCustomField = Omit<
  APITransactionCustomField,
  "transaction"
> & { uuid: string }

export type UpdatableTransactionCustomField = Omit<
  TransactionCustomField,
  "transactionId"
> & { id: string }

export function decodeCustomField(
  customField: APITransactionCustomField
): TransactionCustomField {
  return {
    id: customField.uuid,
    transactionId: customField.transaction,
    name: customField.name,
  }
}

export function encodeCustomField(customField: TransactionCustomField) {
  return {
    uuid: customField.id,
    transaction: customField.transactionId,
    name: customField.name,
  }
}

export function encodeCreateCustomField(
  customField: CreateTransactionCustomField
) {
  return {
    transaction: customField.transactionId,
    name: customField.name,
  }
}

export function encodeUpdateCustomField(
  customField: UpdatableTransactionCustomField
) {
  return {
    uuid: customField.id,
    name: customField.name,
  }
}
