import * as React from "react"

import { Dialog, Icon } from "@blueprintjs/core"

import { apiClient } from "lib/api"

import { ErrorClient } from "app/config/services/error-reporting"
import { toQueryString } from "helpers/query-string"
import { useTransactionIdParam } from "helpers/params"

import { useCurrentUser } from "features/auth/withAuth"
import { useTransaction } from "features/transaction/id/api"

import IManageFilePicker from "pages/dms/imanage"
import { FileDoc } from "models/FileDoc"

const pathForDMS = {
  NET_DOCS: "/dms/net-docs",
  IMANAGE: "/dms/imanage",
  SEE_UNITY: "", // not used, just satisfies TS
  NONE: "", // not used, just satisfies TS
}

type DMSApplicationDialogProps = {
  multipleFiles?: boolean
  onClose?: () => void
  onAddPendingDMSDocs?: (dmsDocs: FileDoc[]) => void
}

export default function DMSApplicationDialog({
  multipleFiles = true,
  onClose,
  onAddPendingDMSDocs = () => null,
}: DMSApplicationDialogProps) {
  let { currentUser } = useCurrentUser()
  let transactionId = useTransactionIdParam()
  let { data: transaction } = useTransaction(transactionId, { enabled: false })

  let refetchDocuments = () =>
    apiClient.refetchQueries([transactionId, "documents", { inQueue: true }])

  let onCloseHandler = () => {
    onClose?.()
    refetchDocuments()
  }

  let addDMSDocsToQueue = (evt) => {
    onAddPendingDMSDocs(evt.detail)
    if (!multipleFiles) {
      onCloseHandler()
    }
  }

  React.useEffect(() => {
    let onError = (evt) => {
      ErrorClient.notify(new Error(evt.detail))
    }

    window.document.addEventListener("CLOSE", onCloseHandler, false)
    window.document.addEventListener("ADD_TO_QUEUE", addDMSDocsToQueue, false)
    window.document.addEventListener("HTTP_ERROR", onError, false)

    return () => {
      window.document.removeEventListener("CLOSE", onCloseHandler)
      window.document.removeEventListener("ADD_TO_QUEUE", addDMSDocsToQueue)
      window.document.removeEventListener("HTTP_ERROR", onError)
    }
  }, [])

  if (!transaction) return null

  let queryParams = {
    transactionId,
    matterNum: transaction.matterNumber,
    clientNum: transaction.clientNumber,
    selection: multipleFiles ? "multiple" : "single",
  }

  let NDApplicationUrl = currentUser
    ? `${pathForDMS[currentUser.dmsType]}?${toQueryString(queryParams)}`
    : ""

  return (
    <Dialog className="relative h-[74vh] w-[80vw] p-0" isOpen onClose={onClose}>
      <button
        className="absolute right-[-32px] top-[-32px] flex h-8 w-8 rounded-full bg-gray-300 hover:bg-gray-100"
        onClick={onClose}
      >
        <Icon icon="cross" className="m-auto" />
      </button>
      {currentUser?.dmsType === "NET_DOCS" ? (
        <iframe src={NDApplicationUrl} className="h-full" />
      ) : (
        <IManageFilePicker
          onClose={onCloseHandler}
          onUploaded={(d) => addDMSDocsToQueue({ detail: d })}
          selection={multipleFiles ? "multiple" : "single"}
        />
      )}
    </Dialog>
  )
}
