import { toQueryString } from "helpers/query-string"

export default class IMOAuthPopup {
  constructor() {
    this.oauthWindow = null
  }

  open({ queryOptions = {}, onMessage = () => null }) {
    window.removeEventListener("message", onMessage)

    if (this.oauthWindow === null || this.oauthWindow.closed) {
      this.oauthWindow = window.open(
        `/imanage/login?${toQueryString(queryOptions)}`,
        "SimplyAgree - iManage",
        `toolbar=no, menubar=no, width=945, height=700, top=${
          window.top.outerHeight / 2 + window.top.screenY - 700 / 2
        }, left=${window.top.outerWidth / 2 + window.top.screenX - 945 / 2}`
      )
    } else {
      this.oauthWindow.focus()
    }

    window.addEventListener("message", onMessage, false)
  }
}
