export type APIParticipant = {
  uuid: string
  user_uuid: string
  participant_uuid: string
  email: string
  full_name: string
  creator: boolean
  send_esigning_notifications: boolean
  deleted?: string
  created_at: string
}

export type Participant = {
  id: string
  userId: string
  name: string
  email: string
  creator: boolean
  sendEsigningNotifications: boolean
  deleted?: string
  createdAt: string
}

export const decode = (p: APIParticipant): Participant => ({
  sendEsigningNotifications: p.send_esigning_notifications,
  id: p.uuid || p.participant_uuid,
  name: p.full_name,
  email: p.email,
  userId: p.user_uuid,
  creator: p.creator,
  deleted: p.deleted,
  createdAt: p.created_at,
})

// Not encode needed, yet
