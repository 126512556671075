import { isEqualValues, removeKey } from "helpers/object"
import { SortDirection } from "./array/sort"

export function toggleItem<T>(collection: T[], item: T) {
  const itemIndex = collection.indexOf(item)
  if (itemIndex !== -1) {
    return [
      ...collection.slice(0, itemIndex),
      ...collection.slice(itemIndex + 1),
    ]
  } else {
    return [...collection, item]
  }
}

export function toggleItems<T>(collection: T[], items: T[]) {
  return items.reduce(
    (collection, item) => toggleItem(collection, item),
    [...collection]
  )
}

export function addPropertiesToCollection<T>(collection: T[], props = {}) {
  return collection.map((original) => ({ ...original, ...props }))
}

export function insertItemAtIndex<T>(collection: T[], item: T, index: number) {
  return [...collection.slice(0, index), item, ...collection.slice(index)]
}

export function insertItemsAtIndex<T>(
  collection: T[],
  items: T[],
  index: number
) {
  return [...collection.slice(0, index), ...items, ...collection.slice(index)]
}

export function removeItemAtIndex<T>(collection: T[], index: number) {
  return [...collection.slice(0, index), ...collection.slice(index + 1)]
}

export function getRandomItemFromCollection<T>(collection: T[] = []) {
  return collection[Math.floor(Math.random() * collection.length)]
}

export function hasDuplicateValues<T>(arr: T[]) {
  const temp = arr.join("~").toLowerCase()
  const lcArr = temp.split("~")
  return new Set(lcArr).size !== lcArr.length
}

export function isEqualCollections<T>(a: T[] | undefined, b: T[] | undefined) {
  let aArray = a || []
  let bArray = b || []

  if (aArray.length !== bArray.length) return false

  for (let i = 0; i < aArray.length; i++) {
    let aItem = aArray[i]
    let bItem = bArray[i]

    if (aItem && bItem) {
      if (!isEqualValues(aItem, bItem)) return false
    }
  }

  return true
}

export function moveKeyFromItemToItem<T extends Record<string | symbol, any>>(
  collectionInput: T[],
  item: T,
  toIndexOrId: number | string,
  dataKey: keyof T = "data",
  key: string = "id"
) {
  return collectionInput.map((collectionItem, index) => {
    if (
      nestedValue(collectionItem, String(key).split(".")) ===
      nestedValue(item, String(key).split("."))
    ) {
      collectionItem = removeKey(collectionItem, dataKey) as T
    }

    if (index === toIndexOrId) {
      collectionItem[dataKey] = item[dataKey]
    }

    return collectionItem
  })
}

function nestedValue<T extends Record<string, Record<string, any>>>(
  obj: T,
  keyPath: string[] = [],
  keyIndex: number = 0
) {
  const valueAtIndex = obj[keyPath[keyIndex] as string]
  if (typeof valueAtIndex === "object" && keyIndex !== keyPath.length - 1) {
    return nestedValue(valueAtIndex, keyPath, keyIndex + 1)
  }
  return valueAtIndex
}

export function moveItemToIndex<T extends Record<string, any>>(
  collectionInput: T[],
  item: T,
  toIndexInput: number,
  key: string = "id"
) {
  const toIndex = Math.min(collectionInput.length - 1, toIndexInput)
  const collection = [...collectionInput]
  const itemIndex = collection.findIndex(
    (i) =>
      nestedValue(i, String(key).split(".")) ===
      nestedValue(item, String(key).split("."))
  )

  if (itemIndex === toIndex || itemIndex === -1) return collection

  const target = collection[itemIndex]
  const increment = toIndex < itemIndex ? -1 : 1

  for (let i = itemIndex; i !== toIndex; i += increment) {
    collection[i] = collection[i + increment] as T
  }

  collection[toIndex] = target as T

  return collection
}

export function groupByKey<T extends Record<string, any>>(
  collection: T[],
  key: keyof T = "id"
) {
  let initial: { [key: string]: T[] } = {}
  return collection.reduce((groupByKey, item) => {
    const existingItems = groupByKey[item[key]] || []
    groupByKey[item[key]] = [...existingItems, item]
    return groupByKey
  }, initial)
}

export function reduceIntoKeyByValue<T extends Record<string, any> | undefined>(
  collection: T[],
  key: string = "id"
) {
  let initial: { [key: string]: T } = {}
  return collection.reduce((collectionById, item) => {
    collectionById[item?.[key]] = item
    return collectionById
  }, initial)
}

export function replaceItem<T extends Record<string, unknown>>(
  collection: T[],
  item: T,
  key: keyof T = "id"
) {
  return collection.map((collectionItem) => {
    if (collectionItem[key] === item[key]) {
      return { ...item }
    }
    return collectionItem
  })
}

export function mergeItem<T extends Record<string, unknown>>(
  collection: T[],
  item: T,
  key: keyof T = "id"
) {
  return collection.map((collectionItem) => {
    if (collectionItem[key] === item[key]) {
      return { ...collectionItem, ...item }
    }
    return collectionItem
  })
}

export function mergeOrAddItem<T extends Record<string, unknown>>(
  collection: T[],
  item: T,
  key: keyof T = "id"
) {
  if (collection.find((cItem) => cItem[key] === item[key])) {
    return mergeItem(collection, item, key)
  }
  return [...collection, item]
}

type RecordWithId = Record<string, unknown> & { id: string }

export function mergeUniq<T extends RecordWithId>(
  collectionA: T[],
  collectionB: T[]
): T[] {
  let all = [...collectionA, ...collectionB]
  let initial: T[] = []
  return all.reduce<T[]>((result, item) => {
    let existing = result.find((i) => i.id === item.id)

    if (existing) {
      return mergeItem(result, item)
    }

    return [...result, item]
  }, initial)
}

export function uniqByKey<T extends RecordWithId>(
  collection: T[],
  key: keyof T = "id"
) {
  let initial: T[] = []
  return collection.reduce((uniqCollection, item) => {
    const existing = uniqCollection.find((i) => i[key] === item[key])
    if (!existing) {
      return [...uniqCollection, item]
    }
    return uniqCollection
  }, initial)
}

export function uniqByValue<T>(collection: T[]) {
  return Array.from(new Set(collection))
}

export function deleteItemAtPath<T extends Record<string, any>>(
  collection: T[] = [],
  path: (string | number)[] = [],
  nestedCollectionKey: string = "collection",
  identityKey: string = "id"
): T[] {
  const indexOrId = path[0]!
  const indexOrIdType: number | string = typeof indexOrId
  if (path.length === 1) {
    if (indexOrIdType === "string") {
      return collection.filter((item) => item[identityKey] !== indexOrId)
    } else if (indexOrIdType === "number") {
      return removeItemAtIndex(collection, indexOrId as number)
    }
  } else {
    const Comparable = {
      // using `value: string | number` for both to keep typescript happier for usage further down
      // and then using String() or Number() to coerce properly
      string: (value: string | number, stringValue: string) =>
        String(value) === stringValue,
      number: (value: string | number, _: unknown, numberValue: number) =>
        Number(value) === numberValue,
    }
    return collection.map((item, index) => {
      if (
        Comparable[indexOrIdType as keyof typeof Comparable](
          indexOrId,
          item[identityKey],
          index
        )
      ) {
        return {
          ...item,
          [nestedCollectionKey]: deleteItemAtPath(
            item[nestedCollectionKey],
            path.slice(1),
            nestedCollectionKey,
            identityKey
          ),
        }
      }
      return item
    })
  }
  // no op but makes typescript happier
  return []
}

export function insertItemAtPath<T extends Record<string, any>>(
  collection: T[] = [],
  path: number[] = [],
  insertItem: T,
  nestedCollectionKey: string = "collection"
): T[] {
  const pathIndex = path[0]!
  if (path.length === 1) {
    return insertItemAtIndex(collection, insertItem, pathIndex)
  } else {
    return collection.map((item, index) => {
      if (index === pathIndex) {
        return {
          ...item,
          [nestedCollectionKey]: insertItemAtPath(
            item[nestedCollectionKey],
            path.slice(1),
            insertItem,
            nestedCollectionKey
          ),
        }
      }
      return item
    })
  }
}

export function moveItemToPath<T extends Record<string, unknown>>(
  collection: T[] = [],
  toPath: number[] = [],
  item: Record<string, any>,
  nestedCollectionKey: string = "collection"
) {
  if (!item.path) {
    return [
      insertItemAtPath(collection, toPath, item, nestedCollectionKey),
      toPath,
    ]
  }
  let workingCollection = [...collection]
  workingCollection = deleteItemAtPath(
    workingCollection,
    item.path,
    nestedCollectionKey
  )
  const oldLocationLeafNode = item.path[item.path.length - 1]
  const itemDepth = item.path.length
  const itemDepthIndex = itemDepth - 1
  const newPath = toPath.map((node, index) => {
    if (index === itemDepthIndex) {
      if (Number(oldLocationLeafNode) < node) {
        return node - 1
      }
    }
    return node
  })
  return [
    insertItemAtPath(workingCollection, newPath, item, nestedCollectionKey),
    newPath,
  ]
}

export function sortByPath<T extends Record<string, any>>(
  collection: T[],
  direction: SortDirection = "asc"
) {
  function compare(a: T, b: T, depth = 0) {
    const lessThanResult = direction === "desc" ? 1 : -1
    const greaterThanResult = direction === "desc" ? -1 : 1

    const initialA = a.path?.[depth]
    const initialB = b.path?.[depth]

    if (initialA === undefined && initialB === undefined) return 0

    // Item with path sorts before item without path
    if (initialA === undefined && initialB !== undefined)
      return greaterThanResult
    if (initialA !== undefined && initialB === undefined) return lessThanResult

    const aPath = initialA || 0
    const bPath = initialB || 0

    if (aPath < bPath) return lessThanResult
    if (aPath > bPath) return greaterThanResult

    return compare(a, b, depth + 1)
  }

  return collection.sort((a, b) => compare(a, b))
}
