import { Assignment } from "./Assignment"

export type APIPacket = {
  certificate_document: string | null
  completed_timestamp: string | null
  deed_package: string | null
  deed_submission_timestamp: string | null
  // TODO-TS: use union type "nota" | ??
  esigning_service: string
  latest_document: string | null
  modified_at: string | null
  uuid: string
}

export type Packet = {
  id: string
  signedPacketUrl: string | null
  signingCertificateUrl: string | null
  signingDeedPacketUrl: string | null
  modifiedAt: string | null
  esigningService: string
  packetCompletedAt: string | null
  deedSubmittedAt: string | null
  assignments?: (Assignment & { isPending: boolean })[]
}

export const decode = (input: APIPacket): Packet => ({
  id: input.uuid,
  signedPacketUrl: input.latest_document,
  signingCertificateUrl: input.certificate_document,
  signingDeedPacketUrl: input.deed_package,
  modifiedAt: input.modified_at,
  esigningService: input.esigning_service,
  packetCompletedAt: input.completed_timestamp,
  deedSubmittedAt: input.deed_submission_timestamp,
})
