import { create } from "lib/store"

type UploadQueuetState = {
  drawerIsOpen: boolean
  setDrawerIsOpen: (val: boolean) => void
  isPollingForExternalDMS: boolean
  setIsPollingForExternalDMS: (val: boolean) => void
  isNativeDMSAppOpen: boolean
  setIsNativeDMSAppOpen: (val: boolean) => void
  isPollingForDocuments: boolean
  setIsPollingForDocuments: (val: boolean) => void
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  hasUnsavedChanges: boolean
  setHasUnsavedChanges: (val: boolean) => void
}

export const useUploadQueueState = create<UploadQueuetState>((set) => ({
  drawerIsOpen: false,
  setDrawerIsOpen: (val) =>
    set({
      drawerIsOpen: val,
    }),
  isPollingForExternalDMS: false,
  setIsPollingForExternalDMS: (val) =>
    set({
      isPollingForExternalDMS: val,
    }),
  isNativeDMSAppOpen: false,
  setIsNativeDMSAppOpen: (val) =>
    set({
      isNativeDMSAppOpen: val,
    }),
  isPollingForDocuments: false,
  setIsPollingForDocuments: (val) =>
    set({
      isPollingForDocuments: val,
    }),
  isLoading: false,
  setIsLoading: (val) =>
    set({
      isLoading: val,
    }),
  hasUnsavedChanges: false,
  setHasUnsavedChanges: (val) => set({ hasUnsavedChanges: val }),
}))
