export type APIItemsConfig = {
  [key: string]: {
    include_attachments: boolean
    include_executed_pages: boolean
  }
}

export type ItemsConfig = {
  [key: string]: {
    isIncludingAttachments: boolean
    isIncludingExecutedPages: boolean
  }
}

export const decode = (
  itemsConfig: APIItemsConfig | undefined = {},
  itemUuids: string[]
): ItemsConfig =>
  [...new Set([...itemUuids, ...Object.keys(itemsConfig)])].reduce(
    (config, itemId) => {
      let {
        include_attachments: isIncludingAttachments = false,
        include_executed_pages: isIncludingExecutedPages = true,
      } = itemsConfig[itemId] || {}

      return {
        ...config,
        [itemId]: { isIncludingAttachments, isIncludingExecutedPages },
      }
    },
    {}
  )

export const encode = (itemsConfig: ItemsConfig = {}): APIItemsConfig =>
  Object.keys(itemsConfig).reduce<APIItemsConfig>(
    (config, itemId) => ({
      ...config,
      [itemId]: {
        include_attachments:
          itemsConfig[itemId]?.isIncludingAttachments ?? false,
        include_executed_pages:
          itemsConfig[itemId]?.isIncludingExecutedPages ?? true,
      },
    }),
    {}
  )
