import { Override } from "lib/ts/override"
import {
  type APIPage,
  type Page,
  decode as decodePage,
  encode as encodePage,
  PageWithItemsAndAssignments,
} from "./Pages"
import { APIInstaPageV2 } from "./InstaPageV2"

export type APIItem = {
  complete: boolean
  for_signing: boolean
  frozen_at?: string
  fully_executed: boolean
  is_deed: boolean
  is_supplemental: boolean
  name: string
  name_alt: string
  name_alt_enabled: boolean
  description: string
  order: number
  element_order: number
  pages: (APIPage | APIInstaPageV2)[]
  pages_embedded_in: string | null
  pending_version_document?: string | null
  transaction: string
  uuid: string
  // NOTE: Values below are in the API but they're not used anywhere
  created_at?: string
  date_distributed?: string
  date_released?: string
  signers?: []
}

export type Item = {
  complete: boolean
  dmsMetaData?: {
    dms: string
    id?: string
    lastImportedAt: string
  }
  forSigning: boolean
  frozenAt: string
  fullyExecuted: boolean
  id: string
  isDeed: boolean
  isSupplemental: boolean
  name: string
  nameAlt: string
  nameAltEnabled: boolean
  description: string
  order: number
  elementOrder: number
  pages: Page[]
  pagesEmbeddedIn: string | null
  pendingVersionDocumentId?: string | null
  transactionId: string
}

export type CreatableAPIItem = Pick<APIItem, "name" | "transaction"> &
  Pick<
    Partial<APIItem>,
    | "pages"
    | "order"
    | "element_order"
    | "complete"
    | "for_signing"
    | "pending_version_document"
    | "is_supplemental"
  > & { element_parent?: string; pages_embedded_in?: string }

export type CreatableItem = Pick<Item, "name" | "transactionId"> &
  Pick<
    Partial<Item>,
    | "order"
    | "elementOrder"
    | "complete"
    | "forSigning"
    | "pendingVersionDocumentId"
    | "isSupplemental"
    | "pagesEmbeddedIn"
    // elementParent is only for create and not used on Item in the app
  > & { elementParent?: string }

export type UpdatableAPIItem = Pick<APIItem, "uuid"> &
  Pick<
    Partial<APIItem>,
    | "complete"
    | "for_signing"
    | "frozen_at"
    | "fully_executed"
    | "is_deed"
    | "is_supplemental"
    | "name"
    | "name_alt"
    | "name_alt_enabled"
    | "description"
    | "pages"
    | "pending_version_document"
  >

export type UpdatableItem = Pick<Item, "id"> &
  Pick<
    Partial<Item>,
    | "complete"
    | "forSigning"
    | "frozenAt"
    | "fullyExecuted"
    | "isDeed"
    | "isSupplemental"
    | "name"
    | "nameAlt"
    | "nameAltEnabled"
    | "description"
    | "pages"
    | "pendingVersionDocumentId"
  >

export type TransactionDashboardItem = Override<
  Item,
  {
    pages: PageWithItemsAndAssignments[]
  }
>

export type ItemsWithPagesWithItemsAndAssignments = Override<
  Item,
  {
    pages: PageWithItemsAndAssignments[]
  }
>

const ItemDownloadKinds = [
  "executed_item_with_attachments",
  "executed_item",
  "unexecuted_item",
  "nonexecutable_item",
  "executed_sigs",
] as const

export type ItemDownloadKindType = (typeof ItemDownloadKinds)[number]

export function isItemDownloadKindType(
  value: string
): value is ItemDownloadKindType {
  for (const kind of ItemDownloadKinds) {
    if (kind === value) {
      return true
    }
  }
  return false
}

export const decode = (item: APIItem): Item => ({
  complete: item.complete,
  forSigning: item.for_signing,
  frozenAt: item.frozen_at || "",
  fullyExecuted: item.fully_executed,
  id: item.uuid,
  isDeed: item.is_deed,
  isSupplemental: item.is_supplemental,
  name: item.name,
  nameAlt: item.name_alt,
  nameAltEnabled: item.name_alt_enabled,
  description: item.description,
  order: item.order,
  elementOrder: item.element_order,
  pages: item.pages?.map((page, idx) => decodePage(page, idx)) || [],
  pagesEmbeddedIn: item.pages_embedded_in,
  pendingVersionDocumentId: item.pending_version_document || "",
  transactionId: item.transaction,
})

export const encode = (item: Item): APIItem => ({
  complete: item.complete,
  for_signing: item.forSigning,
  frozen_at: item.frozenAt,
  fully_executed: item.fullyExecuted,
  is_deed: item.isDeed,
  is_supplemental: item.isSupplemental,
  name: item.name,
  name_alt: item.nameAlt,
  name_alt_enabled: item.nameAltEnabled,
  description: item.description,
  order: item.order,
  element_order: item.elementOrder,
  pages: item.pages?.map((page) => encodePage(page)) || [],
  pages_embedded_in: item.pagesEmbeddedIn,
  pending_version_document: item.pendingVersionDocumentId,
  transaction: item.transactionId,
  uuid: item.id,
})

export const encodeItemCreate = (item: CreatableItem): CreatableAPIItem => ({
  complete: item.complete,
  for_signing: item.forSigning,
  is_supplemental: item.isSupplemental,
  name: item.name,
  order: item.order,
  element_order: item.elementOrder,
  element_parent: item.elementParent,
  pending_version_document: item.pendingVersionDocumentId,
  transaction: item.transactionId,
})

export const encodeItemUpdate = (item: UpdatableItem): UpdatableAPIItem => ({
  complete: item.complete,
  for_signing: item.forSigning,
  frozen_at: item.frozenAt,
  fully_executed: item.fullyExecuted,
  is_deed: item.isDeed,
  is_supplemental: item.isSupplemental,
  name: item.name,
  name_alt: item.nameAlt,
  name_alt_enabled: item.nameAltEnabled,
  description: item.description,
  pages: item.pages?.map((page) => encodePage(page)) || undefined,
  pending_version_document: item.pendingVersionDocumentId,
  uuid: item.id,
})
