import * as React from "react"

import { useRouter } from "next/router"

import { useAppConfig } from "app/config"

import { useCurrentUser } from "features/auth/withAuth"

export const BEACON_ID = "e5654ec7-5753-4602-bbf2-96a0ca20d1fa"

type UserHelpProviderProps = {
  children?: React.ReactNode
}

export function UserHelpProvider({ children }: UserHelpProviderProps) {
  let { config } = useAppConfig()
  let { currentUser } = useCurrentUser({ fetchUser: false })
  let { pathname } = useRouter()
  let [isShowing, setIsShowing] = React.useState(false)
  let isAuthPathName =
    (pathname.includes("transaction") &&
      !pathname.includes("transaction/embed")) ||
    pathname === "/" ||
    pathname.includes("admin") ||
    pathname.includes("account")

  React.useEffect(() => {
    if (config?.HIDE_SUPPORT === "True") return
    if (!window.Beacon) return

    if (
      isAuthPathName &&
      currentUser &&
      !currentUser.shouldHideSupport &&
      currentUser.loadStatus === "ORG"
    ) {
      if (!isShowing) {
        window?.Beacon("init", BEACON_ID)
        setIsShowing(true)
      }
    } else {
      if (isShowing) {
        console.log("Hiding beacon")
        window?.Beacon("destroy")
      }
    }
    return () => {
      if (!isAuthPathName && isShowing) {
        console.info("Hiding beacon on unmount")
        window?.Beacon("destroy")
      }
    }
  }, [
    currentUser?.shouldHideSupport,
    currentUser?.loadStatus,
    config,
    isAuthPathName,
    isShowing,
    setIsShowing,
  ])

  React.useEffect(() => {
    if (currentUser && isShowing) {
      window?.Beacon("once", "ready", () => {
        window.Beacon("identify", {
          name: [currentUser?.first_name, currentUser?.last_name].join(" "),
          email: currentUser?.email,
          signature: currentUser?.beacon_signature,
        })
      })
    }
  }, [currentUser, isShowing])

  return <>{children}</>
}
