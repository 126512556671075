import { OrganizationMember } from "./OrganizationMember"

export type APIInvitation = {
  created_at: string
  email: string
  first_name?: string
  last_name?: string
  organization?: string
  organization_name?: string
  status: string
  token: string
  transaction: string | null
  transaction_name: string
  uuid: string
}

export type Invitation = {
  email: string
  firstName?: string
  id: string
  lastName?: string
  name: string // added in useOrgMembersAndInvitations
  organizationId?: string
  status: string
  transactionId: string | null
  transactionName: string
  type?: OrganizationMember["type"] // added in useOrgMembersAndInvitations
}

export const decode = ({
  email,
  first_name: firstName,
  last_name: lastName,
  organization: organizationId,
  status,
  transaction_name: transactionName,
  transaction: transactionId,
  uuid: id,
}: APIInvitation): Invitation => ({
  email,
  firstName,
  id,
  lastName,
  name: `${firstName} ${lastName}`,
  organizationId,
  status,
  transactionId,
  transactionName,
})

export type CreatableInvitation = Partial<Invitation> &
  Pick<Invitation, "email">

export type CreatableAPIInvitation = Partial<APIInvitation> & {
  email: string
}

export type CreatableAPIOrgInvitation = CreatableAPIInvitation & {
  organization: string
}

export const encode = ({
  email,
  firstName: first_name,
  lastName: last_name,
  transactionId: transaction,
  organizationId: organization,
}: Invitation): CreatableAPIInvitation => ({
  email,
  first_name,
  last_name,
  organization,
  transaction,
})

export const encodeCreatable = (
  vars: CreatableInvitation
): CreatableAPIInvitation => ({
  email: vars.email,
  first_name: vars.firstName,
  last_name: vars.lastName,
  organization: vars.organizationId,
  transaction: vars.transactionId,
})
