export function removeKey<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  key: K
): Omit<T, K> {
  const { [key]: _Ignore, ...newObj } = obj

  return newObj
}

export function removeKeys<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  keys: K[]
): Omit<T, K> {
  const newObj = { ...obj }

  for (const key of keys) {
    delete newObj[key]
  }

  return newObj
}

export function removeUndefinedProperties(obj: Record<string, any>) {
  const clone = { ...obj }

  Object.keys(clone).forEach(
    (key) => clone[key] === undefined && delete clone[key]
  )

  return clone
}

export function nestedValuesForKey<T extends Record<string, any>>(
  obj: T,
  nestedObjKey: string,
  valueKey: string
): T[] {
  let nestedObj = obj[nestedObjKey]

  if (nestedObj) {
    return [
      nestedObj[valueKey],
      ...nestedValuesForKey(nestedObj, nestedObjKey, valueKey),
    ]
  }
  return []
}

export function isEqualValues(
  object1: Record<string, any>,
  object2: Record<string, any>
) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }
  return true
}
