export type APINote = {
  uuid: string
  note: string
  transaction: string
  element: string
  created_at: string
}
export type CreatableAPINote = Pick<APINote, "note" | "transaction" | "element">

export type Note = {
  id: string
  note: string
  transactionId: string
  elementId: string
  createdAt: string
}

export type CreatableNote = Pick<Note, "note" | "transactionId" | "elementId">

export const encodeNoteCreate = (note: CreatableNote): CreatableAPINote => ({
  note: note.note,
  transaction: note.transactionId,
  element: note.elementId,
})

export const decode = (note: APINote): Note => ({
  id: note.uuid,
  note: note.note,
  transactionId: note.transaction,
  elementId: note.element,
  createdAt: note.created_at,
})
