export type APISigningGroupAssignment = {
  uuid: string
  item: string
  item_page_uuid: string
  signing_group: string
  header: string
}

export type SigningGroupAssignment = {
  id: string
  itemId: string
  itemPageId: string
  signingGroupId: string
  header: string
}

export const decodeSigningGroupAssignment = (
  data: APISigningGroupAssignment
): SigningGroupAssignment => ({
  id: data.uuid,
  itemId: data.item,
  itemPageId: data.item_page_uuid,
  signingGroupId: data.signing_group,
  header: data.header,
})
