export type TabTypes =
  | "signature"
  | "initial"
  | "full_name"
  | "title"
  | "date"
  | "email"
  | "checkbox"
  | "custom"
  | "custom_opt"

type APITabTypes = Exclude<TabTypes, "custom_opt">

export type APITab = {
  uuid: string
  assignment: string
  x_position: number
  y_position: number
  tab_type: APITabTypes
  required: boolean
}

export type Tab = {
  id: string
  assignmentId: string
  xPosition: number
  yPosition: number
  tabType: TabTypes
  required: boolean
}

export type UpdatableTab = Partial<Omit<Tab, "id">> & { id: string }
export type UpdatableAPITab = Partial<Omit<APITab, "uuid">> & { uuid: string }
// export type UpdatableAPITab

export type CreatableTab = Omit<Tab, "id">
export type CreatableAPITab = Omit<APITab, "uuid">

export const decode = (tab: APITab): Tab => ({
  id: tab.uuid,
  assignmentId: tab.assignment,
  xPosition: tab.x_position,
  yPosition: tab.y_position,
  tabType: tab.tab_type,
  required: tab.required,
})

export const encodeCreate = (tab: CreatableTab): CreatableAPITab => ({
  assignment: tab.assignmentId,
  x_position: tab.xPosition,
  y_position: tab.yPosition,
  tab_type: tab.tabType === "custom_opt" ? "custom" : tab.tabType,
  required: tab.tabType === "custom_opt" ? false : tab.required,
})

export const encodeUpdate = (tab: UpdatableTab): UpdatableAPITab => ({
  uuid: tab.id,
  assignment: tab.assignmentId,
  x_position: tab.xPosition,
  y_position: tab.yPosition,
  tab_type: tab.tabType === "custom_opt" ? "custom" : tab.tabType,
  required: tab.tabType === "custom_opt" ? false : tab.required,
})
