import { useEffect, useState, FormEvent } from "react"
import {
  Button,
  Checkbox,
  Tag,
  Icon,
  EditableText,
  Intent,
  Position,
  Radio,
  Spinner,
} from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import { motion, AnimatePresence } from "framer-motion"

import { useHasEverChangedTo } from "helpers/hooks"
import TimeIcon from "icons/array-timestamp.svg"
import { Card, RadioGroup } from "../ui"
import { errorMsgForFailure, isPersistable } from "../operations"
import { useUploadPercentComplete } from "../hooks"
import { useDeleteDocuments, useUpdateDocument } from "../api"
import { FileDoc } from "models/FileDoc"
import { SigningType } from "models/Document"
import { useTransactionIdParam } from "helpers/params"
import { useUploadQueueContext } from "../UploadQueueContext"
import useToaster from "helpers/toaster"
import { useUploadQueueState } from "../useUploadQueueState"
import styled from "@emotion/styled"

// customize the Spinner to have a slower animation
const CustomSpinner = styled(Spinner)`
  & .bp4-spinner-animation {
    animation-duration: 750ms;
  }
`

type DocumentQueueCardProps = {
  file: FileDoc
  // if last card and there are multiple, don't show border on the bottom
  isLastCard: boolean
  onSelect: (id: string) => void
  onSelection: (t: SigningType) => void
  onUpdate?: (doc: FileDoc) => void
  selectedOption?: SigningType
}

export default function DocumentQueueCard({
  file: { id, isSelected, name, numPages, signingType, status },
  isLastCard,
  onSelect,
  onSelection,
  onUpdate,
  selectedOption,
}: DocumentQueueCardProps) {
  const { state, dispatch } = useUploadQueueContext()
  const transactionId = useTransactionIdParam()
  const { failure: toasterFailure, success } = useToaster()
  const [currentName, setCurrentName] = useState(name)
  const [isEditingName, setIsEditingName] = useState(false)
  const didConversionHappen = useHasEverChangedTo("CONVERSION_COMPLETE", status)
  const percentComplete = useUploadPercentComplete(status, numPages)
  const { setIsLoading } = useUploadQueueState()

  useEffect(() => {
    setCurrentName(name)
    setIsEditingName(false)
  }, [name])

  const isControlled = !!onUpdate

  const hasError = status.includes("FAILED")

  const { mutate: editDocument } = useUpdateDocument()

  function handleSelect() {
    onSelect(id)
  }

  const { mutateAsync: bulkDeleteDocuments } = useDeleteDocuments(
    transactionId,
    {
      onError: () => {
        toasterFailure("There was a problem deleting the document")
        setIsLoading(false)
      },
    }
  )

  const files = state[transactionId]?.files || []
  const persistedDocuments = files.filter(isPersistable)
  const persistedDocumentIds = persistedDocuments.map((d) => d.id)

  function handleDelete() {
    dispatch({ type: "REMOVE_DOCUMENTS", documentIds: [id], transactionId })
    const isPersistedAlready = persistedDocumentIds?.includes(id)

    if (isPersistedAlready) {
      setIsLoading(true)
      bulkDeleteDocuments([id]).then(() => {
        success("Document has been removed from the Upload Queue.")
      })
    }
  }

  function submitNewName(newName: string) {
    if (isControlled) {
      if (newName.trim() !== name) {
        onUpdate({ id, name: newName, status, signingType })
      }
    } else {
      editDocument({ id, name: newName })
    }
    setIsEditingName(false)
  }

  const handleChange = ({
    currentTarget: { value },
  }: FormEvent<HTMLInputElement>) => {
    if (
      value === "to_be_signed" ||
      value === "non_executable" ||
      value === "fully_executed"
    ) {
      onSelection(value)
    }
  }

  return (
    <div
      className={`ml-4 mr-4 pl-3 py-1.5 grid grid-cols-[minmax(0,_1fr)_300px] lg:grid-cols-[minmax(0,_1fr)_500px] hover:bg-[#eff0f2] group ${
        isLastCard ? "" : "border-b"
      }`}
    >
      <Card>
        <div className="flex p-1 items-center">
          <div className="w-8 -ml-3 mr-0.5 flex justify-start items-center flex-shrink-0">
            <AnimatePresence>
              {!hasError &&
                status &&
                status !== "QUEUED" &&
                status !== "CONVERSION_COMPLETE" && (
                  <div className="relative w-6 h-8 flex items-center">
                    <CustomSpinner
                      className="absolute opacity-60"
                      size={20}
                      intent={Intent.PRIMARY}
                    />
                    <Spinner
                      className="absolute opacity-80"
                      size={20}
                      intent={Intent.PRIMARY}
                      value={percentComplete / 100}
                    />
                  </div>
                )}
              {status === "QUEUED" && (
                <Tooltip2
                  content="Waiting for document to be processed."
                  position={Position.TOP}
                >
                  <div className="color-light-gray-2 flex">
                    <TimeIcon
                      className="m-auto"
                      height="20"
                      width="20"
                      viewBox="0 0 16 16"
                    />
                  </div>
                </Tooltip2>
              )}
              {hasError && (
                <Tooltip2
                  content={errorMsgForFailure(status)}
                  position={Position.TOP}
                >
                  <Icon icon="error" intent={Intent.DANGER} size={20} />
                </Tooltip2>
              )}

              {status === "WAITING_ON_DMS" && (
                <Tooltip2
                  content={
                    <div className="w-88">
                      After selecting a document from your Document Management
                      System, it may take a few moments for it to appear here.
                      Please do not close this window.
                    </div>
                  }
                  position={Position.TOP}
                >
                  <Icon icon="warning-sign" intent={Intent.WARNING} size={20} />
                </Tooltip2>
              )}

              {didConversionHappen && !hasError && (
                <motion.div
                  key="success-check"
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: [0, 1, 1, 1, 1, 0],
                    scale: [0, 1.2, 1, 1, 1.2, 0],
                    transition: {
                      times: [0, 0.14, 0.17, 0.83, 0.86, 1],
                      duration: 2.2,
                    },
                  }}
                >
                  <Icon icon="tick-circle" intent={Intent.SUCCESS} size={20} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="flex min-w-0 flex-grow items-center">
            <Checkbox
              large
              checked={isSelected ?? false} // fix for "uncontrolled" warning in console by defaulting to false if isSelected is temporarily undefined during upload
              onChange={handleSelect}
            />

            <div className="name flex items-center justify-center">
              <EditableText
                className={status === "WAITING_ON_DMS" ? "text-gray-400" : ""}
                confirmOnEnterKey
                isEditing={isEditingName}
                disabled={hasError || status === "WAITING_ON_DMS"}
                maxLength={350}
                value={
                  status === "WAITING_ON_DMS"
                    ? "Accessing document...."
                    : currentName
                }
                onChange={setCurrentName}
                onConfirm={submitNewName}
              />
              {!hasError && (
                <Tooltip2
                  content="Edit"
                  position={Position.TOP}
                  hoverOpenDelay={500}
                >
                  <Button
                    className="ml-2 group-hover:visible invisible"
                    icon="edit"
                    minimal
                    onClick={() => setIsEditingName(true)}
                  />
                </Tooltip2>
              )}
              <Tooltip2
                content="Delete"
                position={Position.TOP}
                hoverOpenDelay={500}
              >
                <Button
                  className="group-hover:visible invisible"
                  icon="trash"
                  minimal
                  onClick={handleDelete}
                />
              </Tooltip2>
            </div>
          </div>
          <div className="ml-auto flex items-center">
            {status !== "WAITING_ON_DMS" && (
              <>
                {signingType === "non_executable" && (
                  <Tag round minimal>
                    Non-executable
                  </Tag>
                )}
                {/* Tag to display when document is executed */}
                {signingType === "fully_executed" && (
                  <Tag intent={Intent.PRIMARY} round minimal>
                    Executed
                  </Tag>
                )}
              </>
            )}
          </div>
        </div>
      </Card>
      <div className="flex items-center">
        <RadioGroup onChange={handleChange} selectedValue={selectedOption}>
          <Radio large value="non_executable" />
          <Radio large value="to_be_signed" />
          <Radio large value="fully_executed" />
        </RadioGroup>
      </div>
    </div>
  )
}
