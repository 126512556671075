import { apiClient, useAPI, useAPIMutation } from "lib/api"
import { ExternalHttp as Http } from "lib/http"
import { updateNotification } from "helpers/api"
import {
  type APINotification,
  decode as decodeNotification,
} from "models/Notification"

const INTERVAL_VALUE = process.env.NOTIFICATIONS_POLLING_INTERVAL

const PollingInterval =
  INTERVAL_VALUE && INTERVAL_VALUE !== "None" ? Number(INTERVAL_VALUE) : false

const getNotifications = (): Promise<APINotification[]> =>
  Http.get<APINotification[]>("/api/notifications/").then((result) => {
    if (result.isError) {
      if (typeof result.data === "string") {
        throw new Error(result.data)
      }

      throw new Error("Error loading notifications")
    }

    if (Array.isArray(result.data)) {
      return result.data
    }

    return []
  })

export function useNotificationsAPI(options = {}) {
  let result = useAPI(["notifications"], () => getNotifications(), {
    ...options,
    select: (data) => data.map(decodeNotification),
    refetchInterval: PollingInterval,
    retry: false,
  })

  return result
}

export function useNotificationsMarkUnreadAsRead() {
  let result = useAPIMutation(
    async () => {
      let unreadNotifications =
        apiClient
          .getQueryData<APINotification[]>(["notifications"])
          ?.filter((n) => !n.read) || []

      if (unreadNotifications.length === 0) {
        return
      }

      return Promise.all(
        unreadNotifications.map((n) =>
          updateNotification(n.uuid, { read: true })
        )
      )
    },
    {
      onSuccess: () => {
        apiClient.refetchQueries(["notifications"])
      },
    }
  )

  return { ...result, markUnreadAsRead: result.mutate }
}
