export { default as withAuth } from "./withAuth"

import { getCookie } from "helpers/cookie/get"
import { http } from "helpers/http"

type AcceptInvitationParams = {
  email: string
  password: string
  firstName: string
  lastName: string
  token: string
  applicationId: string
  tenantId: string
}

export default class Auth {
  static async acceptInvitation({
    email,
    password,
    firstName: first_name,
    lastName: last_name,
    token,
    applicationId,
    tenantId,
  }: AcceptInvitationParams) {
    await http.post({
      url: "/invitations/invitation/register",
      body: {
        email,
        password,
        first_name,
        last_name,
        token,
      },
    })
    return Auth.signIn(email, password, applicationId, tenantId)
  }

  static async changePassword(
    loginId: string,
    currentPassword: string,
    newPassword: string
  ) {
    return http.patch({
      url: "/auth/password",
      body: {
        loginId,
        currentPassword,
        newPassword,
      },
    })
  }

  static async signIn(
    loginId: string,
    password: string,
    applicationId: string,
    tenantId: string
  ) {
    return http.post({
      url: "/auth/login",
      body: {
        loginId,
        password,
        applicationId,
        tenantId,
      },
    })
  }

  static async completeNewPassword(
    password: string,
    passwordId: string,
    tenantId: string
  ) {
    return http.post({
      url: "/auth/password",
      body: { password, passwordId, tenantId },
    })
  }

  static signOutTracker: null | Promise<any> = null

  static async signOut() {
    try {
      if (this.signOutTracker) {
        return this.signOutTracker
      }
      this.signOutTracker = http.delete({ url: "/auth/logout" })

      return this.signOutTracker
    } catch (e) {
      this.signOutTracker = null
      return
    }
  }

  static async refreshImpl() {
    try {
      await http.post({ url: "/auth/refresh" })
    } catch (e) {
      await Auth.signOut()
    }
  }

  static refreshTracker: null | Promise<void> = null

  static async refresh() {
    if (this.refreshTracker) {
      await this.refreshTracker
    } else {
      this.refreshTracker = this.refreshImpl()
      await this.refreshTracker
      this.refreshTracker = null
    }
  }

  static async check() {
    const expiration = getCookie("SAExp")

    if (expiration) {
      const isTokenExpired =
        new Date().getTime() >
        new Date(Number(expiration) * 1000).getTime() - 30 * 60 * 1000 // 30 minutes

      if (isTokenExpired) {
        await Auth.refresh()
      }
    }
  }

  static async requestPasswordReset(
    loginId: string,
    applicationId: string,
    tenantId: string
  ) {
    return http.post({
      url: "/auth/password/reset",
      body: {
        loginId,
        applicationId,
        tenantId,
      },
    })
  }
}
