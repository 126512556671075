import { Item } from "models/Item"

export default function withDocumentDmsMetaData(
  item: Item,
  documentsById: { [key: string]: Record<string, any> }
) {
  let documentTally = item.pages.reduce<Record<string, number>>(
    (counts, page) => {
      counts[page.refId] = (counts[page.refId] || 0) + 1
      return counts
    },
    {}
  )

  return (
    documentsById[
      Object.keys(documentTally).sort(
        (a, b) => Number(documentTally[b]) - Number(documentTally[a])
      )[0]!
    ]?.metaData || null
  )
}
