import { SignatureBlock } from "./Blocks/SignatureBlock"

export interface APISignatureBlockTemplate {
  uuid: string
  transaction: string
  data: SignatureBlock
}

export interface SignatureBlockTemplate {
  id: string
  transactionId: string
  data: SignatureBlock
}

export function decodeSignatureBlockTemplate(
  apiSignatureBlockTemplate: APISignatureBlockTemplate
): SignatureBlockTemplate {
  return {
    id: apiSignatureBlockTemplate.uuid,
    transactionId: apiSignatureBlockTemplate.transaction,
    data: apiSignatureBlockTemplate.data,
  }
}
