export const http = (
  urlInput: string,
  body?: any,
  config: {
    headers?: Record<string, string>
    method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE"
  } = {}
) => {
  const headers = {
    "content-type": "application/json",
  }

  const requestConfig = {
    ...config,
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      ...headers,
      ...config.headers,
    },
  }

  const url = urlInput.includes("http") ? urlInput : "/api" + urlInput

  return fetch(url, requestConfig).then(async (res) => {
    let data

    try {
      data = await res.json()
    } catch (error) {
      data = { result: "Error parsing JSON response" }
    }

    if (res.ok) {
      return data
    } else {
      if (res.status === 302) {
        return { status: 302, url: data.url || res.url }
      }

      return Promise.reject({ ...data, error: true, status: res.status })
    }
  })
}

type HTTPConfig = {
  url: string
  body?: any
  config?: Record<string, any>
}

http.get = (args: HTTPConfig) =>
  http(args.url, null, { ...(args.config || {}), method: "GET" })

http.post = (args: HTTPConfig) =>
  http(args.url, args.body, { ...(args.config || {}), method: "POST" })

http.put = (args: HTTPConfig) =>
  http(args.url, args.body, { ...(args.config || {}), method: "PUT" })

http.patch = (args: HTTPConfig) =>
  http(args.url, args.body, { ...(args.config || {}), method: "PATCH" })

http.delete = (args: HTTPConfig) =>
  http(args.url, null, { ...(args.config || {}), method: "DELETE" })
