import getKeys from "lib/ts/get-keys"
import { v4 as uuid } from "uuid"

type DMSOptions = {
  fusionUrl?: string
  transaction_uuid?: string
  user_uuid?: string
  upload_type: "new_attachment" | "new_item" | "new_version"
  document_uuid?: string
  item_uuid?: string
}

export default class DMSSystem {
  static open({ fusionUrl, ...options }: DMSOptions) {
    // NOTE the .html file will need to change for future 'Save to DMS' feature
    const htmlDoc = "lAppSimplyAgree.html"

    const optionsWithItemId = { ...options, item_uuid: uuid() }

    const params = getKeys(optionsWithItemId).reduce(
      (queryString, key, index) => {
        return `${queryString}${index > 0 ? "&" : ""}${key}=${
          optionsWithItemId[key as keyof DMSOptions["item_uuid"]]
        }`
      },
      ""
    )

    window.open(
      `${fusionUrl}/${htmlDoc}?${params}`,
      "SimplyAgree DMS Select",
      "width=1000,height=800"
    )
  }
}
