import * as React from "react"

import { ExternalHttp as Http } from "lib/http"
import IMOAuthPopup from "app/dms-system/imanage/oauth-popup"
import useIManageConfigId from "app/dms-system/imanage/use-imanage-config-id"

type Options = {
  initialStatus?: string
  onAuthenticated?: () => void
}
export default function useAuth(options: Options = {}) {
  let { configId, availableConfigs, setConfigId } = useIManageConfigId()
  let [iManageUser, setImanageUser] = React.useState(null)
  let [dialogToken, setDialogToken] = React.useState(null)
  let [authStatus, setAuthStatus] = React.useState(
    options.initialStatus === "IDLE"
      ? "IDLE"
      : configId
        ? "AUTHENTICATING"
        : availableConfigs?.length && availableConfigs.length > 0
          ? "SELECT_CONFIG"
          : "ERROR"
  )

  let currentConfig = React.useMemo(() => {
    return availableConfigs?.find((imConfig) => imConfig.id === configId)
  }, [availableConfigs, configId])

  React.useEffect(() => {
    if (authStatus === "SELECT_CONFIG" && configId) {
      setAuthStatus("AUTHENTICATING")
    }
  }, [configId, authStatus])

  React.useEffect(() => {
    async function authIManage() {
      let result = await Http.get(`/api/dms/imanage/me`)
      if (result.status === 401) {
        // If we don't want to authenticate yet and only check if
        // authenticated do nothing if we aren't logged in to iManage
        if (authStatus === "IDLE") return

        return setAuthStatus("NOT_AUTHENTICATED")
      }

      if (result.status >= 400) return setAuthStatus("ERROR")

      if (authStatus === "AUTHENTICATING") {
        setImanageUser(result.data)
        options.onAuthenticated?.()
      }
      setAuthStatus("AUTHENTICATED")
    }

    if (
      !iManageUser &&
      authStatus === "AUTHENTICATING" &&
      currentConfig?.server_host
    ) {
      authIManage()
    }
  }, [authStatus, iManageUser, currentConfig])

  let openOAuthPopup = React.useCallback(() => {
    new IMOAuthPopup().open({
      queryOptions: currentConfig,
      onMessage: (event) => {
        if (event.data.status === "success") {
          setAuthStatus("AUTHENTICATING")
        }
      },
    })
  }, [currentConfig, setAuthStatus])

  React.useEffect(() => {
    if (authStatus === "NOT_AUTHENTICATED") {
      openOAuthPopup()
    }
  }, [authStatus, openOAuthPopup])

  React.useEffect(() => {
    async function getDialogToken() {
      let result = await Http.post("/api/dms/imanage/dialog")
      setDialogToken(result.data.dialog_token)
    }

    if (iManageUser && !dialogToken) {
      getDialogToken()
    }
  }, [iManageUser, dialogToken, currentConfig])

  let data = iManageUser
    ? {
        appId: iManageUser.app?.id || currentConfig?.oauth_client_id,
        customerId: iManageUser.user.customer_id,
        dialogToken,
      }
    : undefined

  function authenticate() {
    if (!currentConfig) {
      setAuthStatus("SELECT_CONFIG")
      return
    }
    setAuthStatus("AUTHENTICATING")
  }

  return {
    authenticate,
    status: authStatus,
    data,
    config: { currentConfig, availableConfigs, setConfigId },
    reloadOAuthPopup: openOAuthPopup,
  }
}
