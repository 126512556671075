import UploadIconActive from "icons/upload-active.svg"
import SelectFileButtons from "../SelectFileButtons"

type DocumentsQueueEmptyStateProps = {
  onOpenDMS: () => void
  openFileDialog: () => void
}

export default function DocumentsQueueEmptyState({
  onOpenDMS,
  openFileDialog,
}: DocumentsQueueEmptyStateProps) {
  return (
    <div className="flex flex-col items-center gap-4 justify-center w-full">
      <UploadIconActive
        className="flex-shrink-0 h-16 w-16"
        viewBox="0 0 35 35"
      />

      <h3 className="text-base tracking-normal font-semibold">
        Select documents or drag and drop.
      </h3>
      <SelectFileButtons
        onOpenDMS={onOpenDMS}
        openFileDialog={openFileDialog}
      />
    </div>
  )
}
