import React from "react"
import { Intent, ToastProps } from "@blueprintjs/core"

import { ToasterContext } from "helpers/contexts"

type ToasterMessage = ToastProps["message"]
type ToasterTimeout = ToastProps["timeout"]

export default function useToaster(context = ToasterContext) {
  const { toaster } = React.useContext(context)

  return {
    success(message: ToasterMessage) {
      toaster?.show({
        message,
        intent: Intent.SUCCESS,
        icon: "tick",
      })
    },
    failure(message: ToasterMessage, timeout: ToasterTimeout = 5000) {
      toaster?.show({
        message,
        intent: Intent.DANGER,
        icon: "error",
        timeout,
      })
    },
    neutral(message: ToasterMessage, timeout?: ToasterTimeout) {
      toaster?.show({
        message,
        intent: Intent.PRIMARY,
        timeout,
      })
    },
  }
}
