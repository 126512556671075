import Router, { useRouter } from "next/router"

export function useItemIdParam(): string {
  let { query } = useRouter()

  return String(query.itemId)
}

export function useTransactionIdParam(): string {
  let { query } = useRouter()

  return (query.id as string) || (query.transactionId as string)
}

export function useProjectIdParam(): string {
  let { query } = useRouter()

  return (query.id as string) || (query.projectId as string)
}

export function getTransactionIdParam() {
  return String(Router.query.id)
}

/**
 * Based on our file structure and Next's routing, we can determine if the user is on the transaction id page by checking the pathname.
 * Any sub page of the transaction by id will have a pathname that is greater than 3 segments long.
 * @returns {boolean} isUserOnTransactionIdPage
 */
export function useIsUserOnTransactionIdPage() {
  const router = useRouter()
  return {
    isUserOnTransactionIdPage: router.pathname.split("/").length === 3,
  }
}

export function useMatterNumParam() {
  let { query } = useRouter()

  return query.matterNum
}

export function useParam(param: string, defaultValue?: string): string {
  let { query } = useRouter()

  let queryValue = query[param]

  if (typeof queryValue !== "string") {
    return defaultValue ?? ""
  }

  return queryValue
}
