export type UpdatableElementOrder = {
  id: string
  order: number
  parentElement: string | null
}

export type BulkUpdatableElementOrder = {
  ids: string[]
  order: number
  parentElement: string | null
}

export type APIUpdatableElementOrder = {
  uuid: string
  order: number
  parent_element: string | null
}

export function insertTransactionElementWithOrder<
  T extends APIUpdatableElementOrder,
>(data: T, elements: T[]): T[] {
  return [...elements, data].map((element) => {
    if (element.uuid === data.uuid) {
      return element
    }

    if (element.order >= data.order) {
      return {
        ...element,
        order: element.order + 1,
      }
    }

    return element
  })
}

export function bulkUpdateTransactionElementsWithOrder<
  T extends APIUpdatableElementOrder,
>(data: BulkUpdatableElementOrder, elements: T[]): T[] {
  let results = [...elements]
  let order = data.order

  return [...data.ids].reverse().reduce((acc, id) => {
    let elementPreMove = acc.find((el) => el.uuid === id)

    let output = updateTransactionElementsWithOrder(
      {
        id,
        order: order,
        parentElement: data.parentElement,
      },
      acc
    )

    let elementPostMove = output.find((el) => el.uuid === id)

    if (
      elementPostMove &&
      elementPreMove &&
      elementPostMove.parent_element === elementPreMove.parent_element
    ) {
      let preMoveOrder = elementPreMove.order
      let postMoveOrder = elementPostMove.order

      if (preMoveOrder < postMoveOrder) {
        order--
      }
    }

    return output
  }, results)
}

export default function updateTransactionElementsWithOrder<
  T extends APIUpdatableElementOrder,
>(data: UpdatableElementOrder, elements: T[]): T[] {
  let originalElement = elements.find((element) => element.uuid === data.id)

  if (!originalElement) {
    return elements
  }

  let isChangingParent = originalElement.parent_element !== data.parentElement

  let updatingElement = { ...originalElement }

  let isMovingDown = !isChangingParent && data.order > originalElement.order

  let results: T[] = []
  for (let element of elements) {
    if (element.uuid === data.id) {
      updatingElement.order = data.order + (isMovingDown ? 0 : 1)
      updatingElement.parent_element = data.parentElement
      results.push(updatingElement)
      continue
    }

    if (element.parent_element === data.parentElement) {
      let toOrder = element.order
      if (isChangingParent) {
        if (element.order > data.order) {
          toOrder += 1
        }
      } else {
        if (
          element.order > data.order &&
          element.order < originalElement.order
        ) {
          toOrder += 1
        } else if (
          element.order <= data.order &&
          element.order > originalElement.order
        ) {
          toOrder -= 1
        }
      }

      results.push({
        ...element,
        order: toOrder,
      })
    } else {
      results.push(element)
    }
  }

  return results
}
