import { Icon, Intent, Position, Spinner } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"
import cx from "classnames"
import { AnimatePresence } from "framer-motion"
import { useEffect, useState } from "react"

import { useCurrentUser } from "features/auth/withAuth"
import ComputerIcon from "icons/computer.svg"
import { useUploadQueueState } from "./useUploadQueueState"
import NetDocsWhiteIcon from "icons/netdocs_white.svg"
import IManageWhiteIcon from "icons/imanage_white.svg"
import pluralize from "helpers/pluralize"
import { countOfInProgressUploads } from "./utils"
import { useUploadQueueContext } from "./UploadQueueContext"
import { useTransactionIdParam } from "helpers/params"
// import styled from "@emotion/styled"

// 02/26/2024 Commenting out temporarily as product wants to try it without the animated spinner
// and gather some feedback on the perceived speed of uploads from users.
// customize the Spinner to have a slower animation
// const CustomSpinner = styled(Spinner)`
//   & .bp4-spinner-animation {
//     animation-duration: 750ms;
//   }
// `

export default function UploadQueueButton({
  handleOpenDMS,
  open,
}: {
  handleOpenDMS: () => void
  open: () => void
}) {
  const { state } = useUploadQueueContext()
  const transactionId = useTransactionIdParam()
  const { currentUser } = useCurrentUser({ fetchUser: false })
  const { setDrawerIsOpen } = useUploadQueueState()

  const [inProgressCount, setInProgressCount] = useState(0)
  const [hasInProgressUploads, setHasInProgressUploads] = useState(false)

  const [childTooltipIsOpen, setChildTooltipIsOpen] = useState(false)

  const files =
    state[transactionId]?.files?.filter((f) => f.status !== "REMOVED") ?? []

  const queuedUploadsCount = files.filter((f) => f.status === "QUEUED").length

  useEffect(() => {
    setInProgressCount(countOfInProgressUploads(files) + queuedUploadsCount)
  }, [files])

  useEffect(() => {
    setHasInProgressUploads(inProgressCount > 0)
  }, [inProgressCount])

  const filesCount = files.length

  const uploadProgressText = !hasInProgressUploads
    ? `${filesCount} ${pluralize("document", filesCount)} complete.`
    : `${filesCount - inProgressCount} of ${filesCount} ${pluralize(
        "document",
        filesCount
      )} complete.`

  const failedFiles = files.filter((f) => f.status.includes("FAILED"))
  const failedFilesCount = failedFiles.length
  const failedFilesText = `Failed to upload ${failedFilesCount} of ${filesCount} ${pluralize(
    "document",
    filesCount
  )}.`

  const fileCountText =
    filesCount > 0 ? `(${filesCount - inProgressCount})` : ""

  const buttonClassNames = cx(
    "rounded-full px-3 py-1 leading-8 flex justify-center items-center flex-row cursor-pointer text-sm align-middle text-left min-h-[30px] shadow-lg transition-all duration-200 ease-in-out border",
    {
      "bg-blue-6 text-white hover:bg-button-gradient border-transparent-blue-6 hover:border-[#1820264D]":
        !hasInProgressUploads && failedFilesCount === 0, // Default blue state
      "bg-white text-gray-sa-6 border-transparent-blue-6 hover:bg-[#f2f2f2]":
        hasInProgressUploads && failedFilesCount === 0, // Loading state, white bg
      "bg-white text-gray-sa-6 border-transparent-red-5 hover:bg-[#fbe9e9]":
        failedFilesCount > 0, // Error state, white bg with red border
    }
  )

  return (
    <Tooltip2
      content={failedFilesCount > 0 ? failedFilesText : uploadProgressText}
      position={Position.TOP}
      hoverOpenDelay={500}
      disabled={filesCount === 0 || childTooltipIsOpen}
    >
      <button
        className={buttonClassNames}
        onClick={() => setDrawerIsOpen(true)}
        data-upload-queue-button
      >
        <Icon icon="chevron-up" size={24} className="mr-1" />

        <span className="flex gap-3">
          Upload Queue {fileCountText}
          <AnimatePresence>
            {filesCount > 0 &&
              hasInProgressUploads &&
              failedFilesCount === 0 && (
                <div className="relative w-6 h-8 flex items-center">
                  {/* <CustomSpinner
                    className="absolute opacity-60"
                    size={24}
                    intent={Intent.PRIMARY}
                  /> */}
                  <Spinner
                    className="absolute opacity-80"
                    size={24}
                    intent={Intent.PRIMARY}
                    value={(filesCount - inProgressCount) / filesCount ?? 0}
                  />
                </div>
              )}
            {filesCount > 0 && failedFilesCount > 0 && (
              <span className="w-8 h-8 flex justify-center items-center bg-white border border-[#E34545] rounded-full">
                <Icon icon="high-priority" color="#E34545" size={16} />
              </span>
            )}

            {failedFilesCount === 0 && !hasInProgressUploads && (
              <>
                <Tooltip2
                  content="Upload documents from computer"
                  position={Position.TOP}
                  hoverOpenDelay={500}
                  onOpening={() => setChildTooltipIsOpen(true)}
                  onClosing={() => setChildTooltipIsOpen(false)}
                >
                  <span
                    className="w-8 h-8 border border-transparent-white bg-blue-6 rounded-sm flex justify-center items-center hover:bg-transparent-white"
                    onClick={(e) => {
                      e.stopPropagation()
                      open()
                    }}
                  >
                    <ComputerIcon />
                  </span>
                </Tooltip2>
                {currentUser?.hasDMS && (
                  <Tooltip2
                    content={`Upload documents from ${
                      currentUser?.dmsType === "NET_DOCS"
                        ? "NetDocuments"
                        : "iManage"
                    }`}
                    position={Position.TOP}
                    hoverOpenDelay={500}
                    onOpening={() => setChildTooltipIsOpen(true)}
                    onClosing={() => setChildTooltipIsOpen(false)}
                  >
                    <span
                      className="w-8 h-8 border border-transparent-white bg-blue-6 rounded-sm flex justify-center items-center hover:bg-transparent-white"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleOpenDMS()
                      }}
                    >
                      {currentUser?.dmsType === "NET_DOCS" ? (
                        <NetDocsWhiteIcon className="w-4 h-4" />
                      ) : (
                        <IManageWhiteIcon className="w-6 h-4" />
                      )}
                    </span>
                  </Tooltip2>
                )}
              </>
            )}
          </AnimatePresence>
        </span>
      </button>
    </Tooltip2>
  )
}
