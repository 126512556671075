import { useState, useCallback, useEffect, useRef, RefObject } from "react"
import { create } from "lib/store"
import { useTransactionFeatureFlags } from "features/transaction/id/api"
import { useAppConfig } from "app/config/hooks"

export const useIsMounted = () => {
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return isMounted.current
}

export const useRunOnMount = (func: () => void = () => null) => {
  useEffect(func, [])
}

export const useDidMountEffect = (func: () => void, deps: unknown[]) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
  }, deps)
}

export const useOnUnMountEffect = (func: () => void) => {
  useEffect(() => {
    return func
  }, [])
}

export const useRefDidMount = (
  callback: (c: unknown) => void,
  dependencies: unknown[]
) => {
  const [used, setUsed] = useState(false)
  const [node, setNode] = useState<unknown | null>(null)

  useEffect(() => {
    setUsed(false)
  }, dependencies)

  useEffect(() => {
    if (!used && node) {
      callback(node)
      setUsed(true)
    }
  }, [callback, node, used])

  return setNode
}

export const useOutsideClick = (ref: RefObject<any>, callback: () => void) => {
  const handleOutsideClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleOutsideClick)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  })
}

export const useInsideClick = (ref: RefObject<any>, callback: () => void) => {
  const handleOutsideClick = (event: MouseEvent) => {
    if (ref.current && ref.current.contains(event.target)) {
      callback()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleOutsideClick)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  })
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useFocusInput(condition: boolean = true) {
  const inputRef = useRef<HTMLInputElement>(null)

  const focusInput = useCallback(
    () => inputRef.current?.focus(),
    [inputRef.current]
  )

  useEffect(() => {
    if (inputRef.current && condition) {
      inputRef.current.focus()
    }
  }, [condition])

  return { inputRef, focusInput }
}

export function useDidChangeFromTo(
  fromValue: string,
  toValue: string,
  value: string
) {
  const prevValue = usePrevious(value)

  return (
    (prevValue === fromValue ||
      (!!prevValue && prevValue !== value && fromValue === "*")) &&
    toValue === value
  )
}

export function useHasEverChangedFromTo(
  fromValue: string,
  toValue: string,
  value: unknown
) {
  const hasHadTruthyValue = useRef<typeof value>()
  const prevValue = usePrevious(value)

  const reset = useCallback(() => {
    hasHadTruthyValue.current = false
  }, [])

  if (hasHadTruthyValue.current) return { isTruthy: true, reset }

  const isTruthy =
    (prevValue === fromValue ||
      (!!prevValue && prevValue !== value && fromValue === "*")) &&
    toValue === value

  if (isTruthy) {
    hasHadTruthyValue.current = isTruthy
  }

  return { isTruthy, reset }
}

export function useHasEverChangedTo(toValue: string, value: unknown) {
  const hasHadTruthyValue = useRef<typeof value>()
  const prevValue = usePrevious(value)

  if (hasHadTruthyValue.current) return true

  const isTruthy = !!prevValue && prevValue !== toValue && value === toValue

  if (isTruthy) {
    hasHadTruthyValue.current = isTruthy
  }

  return isTruthy
}

export function useDragToScroll({ enabled = true } = {}) {
  const draggableContainerRef = useRef<HTMLDivElement>(null)
  const [initialMousePosition, setInitialMousePosition] = useState<{
    x: number
    y: number
    left: number
    top: number
  } | null>(null)

  useEffect(() => {
    function onMouseDown(evt: MouseEvent) {
      if (draggableContainerRef.current) {
        setInitialMousePosition({
          x: evt.clientX,
          y: evt.clientY,
          left: draggableContainerRef.current.scrollLeft,
          top: draggableContainerRef.current.scrollTop,
        })
        draggableContainerRef.current.style.cursor = "grabbing"
      }
    }

    function onMouseUp() {
      if (draggableContainerRef.current) {
        draggableContainerRef.current.style.cursor = "grab"
      }
      setInitialMousePosition(null)
    }

    if (draggableContainerRef.current) {
      if (enabled) {
        draggableContainerRef.current.addEventListener("mousedown", onMouseDown)
        draggableContainerRef.current.addEventListener("mouseup", onMouseUp)
      } else {
        draggableContainerRef.current.removeEventListener(
          "mousedown",
          onMouseDown
        )
        draggableContainerRef.current.removeEventListener("mouseup", onMouseUp)
      }
    }
    return () => {
      draggableContainerRef.current?.removeEventListener(
        "mousedown",
        onMouseDown
      )
      draggableContainerRef.current?.removeEventListener("mouseup", onMouseUp)
    }
  }, [draggableContainerRef, enabled])

  useEffect(() => {
    function onDrag(evt: MouseEvent) {
      if (draggableContainerRef.current && initialMousePosition) {
        draggableContainerRef.current.scrollLeft =
          initialMousePosition.left - (evt.clientX - initialMousePosition.x)
        draggableContainerRef.current.scrollTop =
          initialMousePosition.top - (evt.clientY - initialMousePosition.y)
      }
    }

    if (draggableContainerRef.current) {
      if (initialMousePosition) {
        draggableContainerRef.current.addEventListener("mousemove", onDrag)
      } else {
        draggableContainerRef.current.removeEventListener("mousemove", onDrag)
      }
    }

    return () => {
      draggableContainerRef.current?.removeEventListener("mousemove", onDrag)
    }
  }, [draggableContainerRef.current, initialMousePosition])

  return { draggableContainerRef }
}

type TUseUnsavedChanges = {
  hasUnsavedChanges: boolean
  setHasUnsavedChanges: (val: boolean) => void
  showUnsavedChangesModal: boolean
  setShowUnsavedChangesModal: (val: boolean) => void
}

export const useUnsavedChanges = create<TUseUnsavedChanges>((set) => ({
  hasUnsavedChanges: false,
  setHasUnsavedChanges: (val) => set({ hasUnsavedChanges: val }),
  showUnsavedChangesModal: false,
  setShowUnsavedChangesModal: (val) => set({ showUnsavedChangesModal: val }),
}))

export const useTransactionFeatureFlagsConfig = (transactionId: string) => {
  let transactionFeatureFlags = useTransactionFeatureFlags(transactionId)
  let { setConfig } = useAppConfig()

  useEffect(() => {
    if (transactionFeatureFlags?.data && typeof setConfig === "function") {
      const transactionFeatureFlagConfig = {
        FLAGS: {
          // Build out the feature flags here that are specific to the transaction's organization
          INSTAPAGES_V2: Boolean(
            transactionFeatureFlags.data?.instapages_v2?.is_active
          ),
          TASKS: Boolean(transactionFeatureFlags.data?.tasks?.is_active),
          ALTERNATE_DOCUMENT_NAMES: Boolean(
            transactionFeatureFlags.data?.alternate_document_names?.is_active
          ),
          SHOW_SIGNING_GROUP_TAGS: Boolean(
            transactionFeatureFlags.data?.show_signing_group_tags?.is_active
          ),
        },
      }

      setConfig((prev) =>
        prev
          ? { ...prev, ...transactionFeatureFlagConfig }
          : transactionFeatureFlagConfig
      )
    }
  }, [transactionFeatureFlags?.data])
}

export function useLocalStorage<T extends boolean | string>(
  key: string,
  initialValue: T
) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.error("Error reading from localStorage:", error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that persists the new value to localStorage
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      setStoredValue(value)
      window.localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.error("Error writing to localStorage:", error)
    }
  }

  // Listen for changes in other tabs/windows
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key && event.newValue !== null) {
        setStoredValue(JSON.parse(event.newValue))
      }
    }

    window.addEventListener("storage", handleStorageChange)
    return () => window.removeEventListener("storage", handleStorageChange)
  }, [key])

  return [storedValue, setValue] as const
}
