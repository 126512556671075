import {
  type APIOrganizationMember,
  type OrganizationMember,
  decode as decodeOrganizationMember,
} from "./OrganizationMember"

export type APIOrganization = {
  auto_signature_pages: boolean
  custom_help_email: string | null
  custom_help_url: string | null
  disable_signature_application: boolean
  disable_user_survey: boolean
  has_closing_tool: boolean
  has_expired_imanage_transactions: boolean
  has_funding_tool: boolean
  members?: APIOrganizationMember[]
  mercury_signings: boolean
  name: string
  signing_groups: boolean
  uuid: string
  website: string
}

export type Organization = {
  autoSignaturePages: boolean
  customHelpEmail: string | null
  customHelpUrl: string | null
  disableSignatureApplication: boolean
  disableUserSurvey: boolean
  hasClosingTool: boolean
  hasExpiredIManageTransactions: boolean
  hasFundingTool: boolean
  id: string
  members: OrganizationMember[]
  mercurySignings: boolean
  name: string
  signingGroups: boolean
  website: string
}

export const decode = (org: APIOrganization): Organization => ({
  autoSignaturePages: org.auto_signature_pages,
  customHelpEmail: org.custom_help_email,
  customHelpUrl: org.custom_help_url,
  disableSignatureApplication: org.disable_signature_application,
  disableUserSurvey: org.disable_user_survey,
  hasClosingTool: org.has_closing_tool,
  hasExpiredIManageTransactions: org.has_expired_imanage_transactions,
  hasFundingTool: org.has_funding_tool,
  id: org.uuid,
  members: org.members
    ? org.members.map((member) => decodeOrganizationMember(member))
    : [],
  mercurySignings: org.mercury_signings,
  name: org.name,
  signingGroups: org.signing_groups,
  website: org.website,
})
