import { ExternalHttp as Http } from "lib/http"
import { ErrorClient } from "app/config/services/error-reporting"

import { addPropertiesToCollection } from "helpers/array"
import { useTransactionIdParam } from "helpers/params"

function decodeUploads(docs = []) {
  return docs.map(({ name, document_uuid: id }) => ({ id, name }))
}

function encodeUploads(docs = []) {
  return docs.map(({ database: imanage_library_id, extension, name, id }) => ({
    imanage_doc_id: id,
    imanage_library_id,
    name,
    file_name: name + "." + extension,
  }))
}

export function useUploadDocuments() {
  let transaction = useTransactionIdParam()

  async function call(docs = [], imanage_customer_id, inQueueParam) {
    let result = {},
      error

    try {
      result = await Http.post("/api/dms/imanage/download", {
        documents: addPropertiesToCollection(encodeUploads(docs), {
          imanage_customer_id,
          transaction,
          in_queue: inQueueParam,
        }),
      })
      if (result.isError) throw new Error(result.data?.error)
    } catch (e) {
      error = e
      ErrorClient.notify(
        new Error(
          e?.data?.error || `${e.status} error uploading iManage documents`
        )
      )
    }

    return {
      data: result.isError ? result.data : decodeUploads(result.data),
      error,
    }
  }

  return { uploadDocuments: call }
}
