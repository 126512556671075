export type APITask = {
  uuid: string
  name: string
  transaction: string
  element: string
  element_order: number
  element_parent: string
}
export type CreatableAPITask = Pick<APITask, "name" | "transaction"> &
  Pick<Partial<APITask>, "element_order"> & { element_parent?: string }

export type Task = {
  id: string
  name: string
  transactionId: string
  elementId?: string
  elementOrder?: number
  elementParent?: string
}

export type CreatableTask = Pick<Task, "name" | "transactionId"> &
  Pick<Partial<Task>, "elementOrder"> & { elementParent?: string }

export type UpdatableAPITask = Pick<APITask, "name" | "uuid">

export type UpdatableTask = Pick<Task, "name" | "id">

export const encodeTaskUpdate = (task: UpdatableTask): UpdatableAPITask => ({
  name: task.name,
  uuid: task.id,
})

export const encodeTaskCreate = (task: CreatableTask): CreatableAPITask => ({
  name: task.name,
  transaction: task.transactionId,
  element_order: task.elementOrder,
  element_parent: task.elementParent,
})

export const decode = (task: APITask): Task => ({
  id: task.uuid,
  name: task.name,
  transactionId: task.transaction,
})
