import { ReactNode, useEffect, useState } from "react"

type DelayedProps = {
  children?: ReactNode
  ms?: number
}

export default function Delayed({ children, ms }: DelayedProps) {
  let [isShowing, setIsShowing] = useState(false)

  useEffect(() => {
    let timer = setTimeout(() => setIsShowing(true), ms)

    return () => clearTimeout(timer)
  }, [ms])

  return isShowing ? <>{children}</> : null
}
