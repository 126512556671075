import * as React from "react"
import styled from "@emotion/styled"

import Link from "next/link"

const Header = styled.div`
  height: 48px;
  width: 100vw;
  display: flex;
  align-items: center;
  background: white;
  padding-left: 10px;
`

const ContentWrapper = styled.div`
  min-height: calc(100vh - 48px);
  width: 100vw;
  background-image: linear-gradient(141deg, #5a829e 0%, #3c5d74 99%);
`

type ExternalProps = {
  children?: React.ReactNode
}

export default function External({ children }: ExternalProps) {
  return (
    <>
      <Header>
        <Link href="/login">
          <img className="h-7" src="/static/sa-logo-primary-500.png" />
        </Link>
      </Header>
      <ContentWrapper>{children}</ContentWrapper>
    </>
  )
}
