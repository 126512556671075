export type APIOrganizationMember = {
  uuid: string
  email: string
  full_name: string
  is_org_admin: boolean
}

export type OrganizationMember = {
  id: string
  email: string
  name: string
  isOrgAdmin: boolean
  type?: "MEMBER" | "INVITATION" | "ADMIN"
}

export const decode = (
  orgMember: APIOrganizationMember
): OrganizationMember => ({
  id: orgMember.uuid,
  email: orgMember.email,
  name: orgMember.full_name,
  isOrgAdmin: orgMember.is_org_admin,
})
