import {
  createContext,
  useContext,
  useEffect,
  useState,
  type ReactNode,
} from "react"

import { AuthContext } from "helpers/contexts"
import { useNotificationsAPI, useNotificationsMarkUnreadAsRead } from "./api"
import { User } from "models/User"
import { Notification } from "models/Notification"

type NotificationsContextType = {
  notifications: Notification[]
  markUnreadAsRead: () => void
  refetchNotifications: () => void
}

export const NotificationsContext = createContext<NotificationsContextType>({
  notifications: [],
  markUnreadAsRead: () => {},
  refetchNotifications: () => {},
})

export function useNotifications() {
  let context = useContext(NotificationsContext)

  return context
}

type NotificationsProviderProps = {
  children?: ReactNode
  user?: User
}
export default function NotificationsProvider({
  children,
}: NotificationsProviderProps) {
  const [isError, setIsError] = useState(false)
  const { user } = useContext(AuthContext)
  const {
    data: notifications,
    error,
    refetch,
  } = useNotificationsAPI({
    enabled: Boolean(user) && !isError,
  })
  const { markUnreadAsRead } = useNotificationsMarkUnreadAsRead()

  useEffect(() => {
    if (error) {
      console.log("Notifications API ERROR", error)
      setIsError(true)
    }
  }, [error])

  const orderedNotifications = [...(notifications || [])].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )

  return (
    <NotificationsContext.Provider
      value={{
        markUnreadAsRead,
        notifications: orderedNotifications,
        refetchNotifications: refetch,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}
