import * as React from "react"
import { Button, Icon } from "@blueprintjs/core"
import { DropzoneRootProps } from "react-dropzone"
import { motion, AnimatePresence } from "framer-motion"

import { useUploadQueueState } from "./useUploadQueueState"
import { useCurrentUser } from "features/auth/withAuth"
import UploadQueueManager from "./UploadQueueManager"
import { useTransactionIdParam } from "helpers/params"
import { useUploadQueueContext } from "./UploadQueueContext"

type UploadQueueDrawerProps = {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  isDragActive: boolean
  open: () => void
  handleOpenDMS: () => void
}

export default function UploadQueueDrawer({
  getRootProps,
  isDragActive,
  open,
  handleOpenDMS,
}: UploadQueueDrawerProps) {
  const { state } = useUploadQueueContext()
  const { drawerIsOpen, setDrawerIsOpen } = useUploadQueueState()
  const { currentUser } = useCurrentUser({ fetchUser: false })
  const transactionId = useTransactionIdParam()

  const nonRemovedFiles =
    state[transactionId]?.files?.filter((f) => f.status !== "REMOVED") ?? []

  // short and tall drawer height variants
  const drawerHeight = nonRemovedFiles.length > 2 ? "50vh" : "250px"

  React.useEffect(() => {
    let beaconContainer = document.getElementById("beacon-container")
    if (beaconContainer) {
      if (drawerIsOpen) {
        beaconContainer.toggleAttribute("hidden", true)
      } else {
        beaconContainer.toggleAttribute("hidden", false)
      }
    }
  }, [drawerIsOpen])

  return (
    <AnimatePresence>
      <motion.div
        data-upload-queue-drawer
        className="w-full absolute bottom-0 right-0 left-0 bg-white rounded-lg z-50 border border-transparent-blue-6 shadow-2xl"
        initial="collapsed"
        animate={drawerIsOpen ? "open" : "collapsed"}
        exit="collapsed"
        variants={{
          open: {
            opacity: 1,
            height: drawerHeight,
            overflow: "auto",
          },
          collapsed: {
            opacity: 0,
            height: 0,
            overflow: "hidden",
          },
        }}
        transition={{
          duration: 0.2,
          ease: [0, 0.67, 0.83, 0.67],
        }}
      >
        {currentUser && (
          <>
            <UploadQueueManager
              user={currentUser}
              getRootProps={getRootProps}
              isDragActive={isDragActive}
              open={open}
              handleOpenDMS={handleOpenDMS}
            />
            {nonRemovedFiles.length === 0 ? (
              <Button
                icon={<Icon icon="cross" size={24} />}
                minimal
                onClick={() => setDrawerIsOpen(false)}
                className="absolute top-4 right-4"
              />
            ) : null}
          </>
        )}
      </motion.div>
    </AnimatePresence>
  )
}
