import documentStatus, {
  type DocumentStatus,
} from "components/add-documents/DocumentsQueue/DocumentUploadManager/document-status"

export type SigningType = "to_be_signed" | "non_executable" | "fully_executed"

export type APIDocument = {
  uuid: string
  conversion_finished_at?: string
  conversion_requested_at?: string
  error_message?: string
  transaction: string
  name: string
  pdf_file_key?: string
  uploaded_file_key: string
  in_queue: boolean
  signing_type: SigningType
  num_pages?: number | null
  item_id?: string | null
  metadata?: {
    dms: string
    dms_id: string
  }
  thumbnails?: {
    "png-144"?: string
  }[]
  thumbnails_finished_at?: string
  upload_order?: number | null
}

export type Document = {
  id: string
  inQueue: boolean
  itemId: string | null
  transactionId: string
  conversionFinishedAt: string | null
  metaData: {
    dms: string
    id: string
    lastImportedAt: number
  } | null
  name: string
  status: DocumentStatus
  signingType: SigningType
  numPages: number | null
  uploadedFileKey: string
  pdfFileUrl: string | null
  pageThumbnails: string[]
  uploadOrder?: number | null
}

export const decode = (json: APIDocument): Document => {
  const {
    uuid: id,
    conversion_finished_at,
    name,
    num_pages,
    signing_type: signingType,
    in_queue: inQueue,
    item_id,
    metadata,
    pdf_file_key,
    transaction,
    uploaded_file_key: uploadedFileKey,
    upload_order,
  } = json

  return {
    id,
    inQueue,
    itemId: item_id || null,
    transactionId: transaction,
    conversionFinishedAt: conversion_finished_at || null,
    metaData: metadata
      ? {
          dms: metadata.dms,
          id: metadata.dms_id,
          lastImportedAt: new Date(conversion_finished_at || 0).getTime(),
        }
      : null,
    name,
    status: documentStatus(json),
    signingType,
    numPages: num_pages || null,
    pageThumbnails:
      (json.thumbnails || []).map((t) => String(t["png-144"])) || [],
    pdfFileUrl: pdf_file_key || null,
    // TODO-TS: change to uploadedFileUrl
    uploadedFileKey,
    uploadOrder: upload_order || 0,
  }
}

export type CreatableDocument = Pick<
  Document,
  | "id"
  | "inQueue"
  | "name"
  | "signingType"
  | "transactionId"
  | "uploadedFileKey"
  | "uploadOrder"
>

export type CreatableAPIDocument = Pick<
  APIDocument,
  | "in_queue"
  | "name"
  | "signing_type"
  | "transaction"
  | "upload_order"
  | "uploaded_file_key"
  | "uuid"
>
export const encode = ({
  id,
  inQueue: in_queue,
  name,
  signingType: signing_type,
  transactionId: transaction,
  uploadedFileKey: uploaded_file_key,
  uploadOrder: upload_order,
}: CreatableDocument): CreatableAPIDocument => ({
  in_queue,
  name,
  signing_type,
  transaction,
  upload_order,
  uploaded_file_key,
  uuid: id,
})

export type UpdatableDocument = Partial<Document> & Pick<Document, "id">
export type UpdateableAPIDocument = Partial<APIDocument> &
  Pick<APIDocument, "uuid">

export const encodeUpdatable = (
  input: UpdatableDocument
): UpdateableAPIDocument => ({
  uuid: input.id,
  name: input.name,
  signing_type: input.signingType,
  uploaded_file_key: input.uploadedFileKey,
  in_queue: input.inQueue,
})
