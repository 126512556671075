import { Assignment } from "./Assignment"
import {
  APIInstaPageV2,
  InstaPageV2,
  decodeInstaPageV2,
  encodeInstaPageV2,
} from "./InstaPageV2"
import { Item } from "./Item"
import {
  type ItemsConfig,
  type APIItemsConfig,
  decode as decodeItemsConfig,
  encode as encodeItemsConfig,
} from "./ItemsConfig"

const PAGE_TYPES = ["document", "attachment", "instapage"] as const
export type PageType = (typeof PAGE_TYPES)[number]

export type APIPage = {
  uuid: string
  ref_uuid?: string
  ref_page_number?: number
  type: PageType
  for_signing?: boolean
  items_config?: APIItemsConfig
  assignments?: string[]
  items?: string[] // These are item uuids
  label?: string
  name?: string
  enabled?: boolean
  footer?: string
  lead_in?: string
  everyLeadIn?: boolean
  breakAwayPages?: boolean
  replace_page?: boolean
  date?: boolean
  email?: boolean
  notary?: boolean
  address?: boolean
  witness?: boolean
  is_executed_signature_page?: boolean
  pages?: APIPage[]
}

export type PageV1 = {
  address?: boolean
  assignments?: string[]
  breakAwayPages?: boolean
  date?: boolean
  email?: boolean
  enabled?: boolean
  everyLeadIn?: boolean
  footer?: string
  forSigning?: boolean
  frozenPages?: Page[]
  id: string
  isExecutedSignaturePage?: boolean
  items?: string[]
  itemsConfig?: ItemsConfig
  label?: string
  leadIn?: string
  name?: string
  notary?: boolean
  number: number
  refId?: string
  refPageNumber?: number
  replacePage?: boolean
  type: PageType
  witness?: boolean
}

export type Page = PageV1 | InstaPageV2

export type DetectPage = Pick<PageV1, "id" | "refId" | "refPageNumber"> & {
  transactionId: string
  itemId: string
}

export type PageWithItemsAndAssignments = Omit<
  Page,
  "items" | "assignments" | "pages"
> & {
  items: Item[]
  assignments: Assignment[]
}

export const decode = (page: APIPage | APIInstaPageV2, index: number): Page => {
  if (page.type === "instapagev2") {
    return decodeInstaPageV2(page, index)
  }

  return {
    id: page.uuid,
    refId: page.ref_uuid || "",
    refPageNumber: page.ref_page_number || 0,
    type: page.type,
    forSigning: page.for_signing || false,
    itemsConfig:
      page.type === "attachment"
        ? decodeItemsConfig(page.items_config, page.items || [])
        : undefined,
    assignments: page.assignments,
    items: page.items,
    label: page.label,
    name: page.name,
    enabled: page.enabled,
    footer: page.footer,
    leadIn: page.lead_in,
    everyLeadIn: page.everyLeadIn,
    breakAwayPages: page.breakAwayPages,
    replacePage: page.replace_page,
    date: page.date,
    email: page.email,
    notary: page.notary,
    address: page.address,
    witness: page.witness,
    isExecutedSignaturePage: page.is_executed_signature_page,
    number: index,
    // If parent Item is a deed and is frozen, attachments will contain a nested pages: []
    // property. If that exists we set it to frozenPages and format the page data.
    // The original `pages` property is left unchanged and included in the above ...rest
    frozenPages: page.pages
      ? page.pages.map((page, idx) => decode(page, idx))
      : undefined,
    // instaPageV2: page.instapage_v2 && decodeInstaPageV2(page.instapage_v2),
  }
}

export const encode = (page: Page): APIPage | APIInstaPageV2 => {
  if (page.type === "instapagev2") {
    return encodeInstaPageV2(page)
  }

  return {
    uuid: page.id,
    ref_uuid: page.refId,
    ref_page_number: page.refPageNumber,
    type: page.type,
    for_signing: page.forSigning,
    items_config:
      page.type === "attachment"
        ? encodeItemsConfig(page.itemsConfig)
        : undefined,
    assignments: page.assignments,
    items: page.items,
    label: page.label,
    name: page.name,
    enabled: page.enabled,
    footer: page.footer,
    lead_in: page.leadIn,
    everyLeadIn: page.everyLeadIn,
    breakAwayPages: page.breakAwayPages,
    replace_page: page.replacePage,
    date: page.date,
    email: page.email,
    notary: page.notary,
    address: page.address,
    witness: page.witness,
    is_executed_signature_page: page.isExecutedSignaturePage,
  }
}
