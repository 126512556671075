import * as React from "react"

import Delayed from "components/util/Delayed"
import FullPageSpinner from "components/spinners/fullPageSpinner"
import { useFetchAppConfig, useAppConfig } from "./hooks"
import loadServiceForConfig, { SERVICES } from "./load-config-service"
import { AppConfigContext } from "helpers/contexts"

export { useAppConfig }

type ConfigProviderProps = {
  children?: React.ReactNode
}

export function ConfigProvider({ children }: ConfigProviderProps) {
  let { config, isLoading, setConfig, fetchFeatureStatusAndUpdateConfig } =
    useFetchAppConfig()

  React.useEffect(() => {
    if (config) {
      window.__app = { config, services: {} }

      Object.keys(config).forEach((CONFIG_KEY) => {
        loadServiceForConfig(
          CONFIG_KEY as keyof typeof SERVICES,
          config?.[CONFIG_KEY as keyof typeof SERVICES]
        )
      })
    }
  }, [config])

  if (!config || isLoading) {
    return (
      <Delayed ms={100}>
        <FullPageSpinner />
      </Delayed>
    )
  }

  return (
    <AppConfigContext.Provider
      value={{
        setConfig,
        config,
        fetchFeatureStatusAndUpdateConfig,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  )
}
