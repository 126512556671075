import { Spinner, Intent } from "@blueprintjs/core"

const FullPageSpinner = () => (
  <div className="flex h-screen w-screen">
    <Spinner
      className="m-auto"
      intent={Intent.PRIMARY}
      size={Spinner.SIZE_LARGE}
    />
  </div>
)

export default FullPageSpinner
