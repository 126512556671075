import * as React from "react"
import { Toaster as BPToaster, Intent } from "@blueprintjs/core"

import { ToasterContext } from "helpers/contexts"

const IToast =
  typeof window === "undefined"
    ? null
    : BPToaster.create({ className: "fixed" })

type ToastManagerProps = {
  children: React.ReactNode
}

const ToastManager = ({ children }: ToastManagerProps) => (
  <ToasterContext.Provider value={{ toaster: IToast }}>
    {children}
  </ToasterContext.Provider>
)

export default ToastManager

const NullToaster = {
  success() {},
  failure() {},
}

export const Toaster = IToast
  ? {
      success(message) {
        IToast.show({
          message,
          intent: Intent.SUCCESS,
          icon: "tick",
        })
      },
      failure(message) {
        IToast.show({
          message,
          intent: Intent.DANGER,
          icon: "error",
        })
      },
    }
  : NullToaster
