import * as React from "react"

import { getCookie } from "helpers/cookie"

import { useCurrentUser } from "features/auth/withAuth"
import { User } from "models/User"

const ONE_YEAR = 60 * 60 * 24 * 365

function storeIManageConfigId(configId: string) {
  document.cookie = `IMConfigId=${configId}; SameSite=None; Secure; path=/; Max-Age=${ONE_YEAR}`
}

export function getIManageConfigData(user?: User) {
  let configId = null
  let storedConfigId = getCookie("IMConfigId") || null
  let hasSingleConfigOption = user?.imanage_configs?.length === 1
  let availableConfigIds = user?.imanage_configs?.map(({ id }) => id) || []

  if (storedConfigId && availableConfigIds.includes(storedConfigId)) {
    configId = storedConfigId
  }

  if (storedConfigId && !availableConfigIds.includes(storedConfigId)) {
    if (hasSingleConfigOption) {
      configId = availableConfigIds[0]
    }
  }

  if (!storedConfigId && hasSingleConfigOption) {
    configId = availableConfigIds[0]
  }

  if (configId && storedConfigId !== configId) {
    storeIManageConfigId(configId)
  }

  return { configId, availableConfigs: user?.imanage_configs }
}

export default function useIManageConfigId() {
  let { currentUser } = useCurrentUser()
  let { configId, availableConfigs } = getIManageConfigData(currentUser)
  let [currentConfigId, setCurrentConfigId] = React.useState(configId)

  function handleStoreIManageConfigId(id: string) {
    storeIManageConfigId(id)
    // Have to do this so react re-renders
    setCurrentConfigId(id)
  }

  return {
    configId: currentConfigId,
    availableConfigs,
    setConfigId: handleStoreIManageConfigId,
  }
}
