import {
  APISigningGroupAssignment,
  SigningGroupAssignment,
  decodeSigningGroupAssignment,
} from "./SigningGroupAssignment"
import { type APITab, type Tab, decode as decodeTab } from "./Tab"

// TODO-TS: Signers type for the time being until we decide to remove it.
export type Signers = {
  packet: string
  status: string
  uuid: string
}

export type APIAssignment = {
  uuid: string
  auth_rep?: string | null
  packet?: string
  packet_page: number | null
  packet_status?: string
  tabs: APITab[]
  deed_submission_audit_log: string | null
  executed_document: string | null
  executed_document_page: number | null
  signatory: string
  signing_group_assignment: APISigningGroupAssignment | null
  item: string
  item_page_uuid: string | null
  requires_notary: boolean
  status: string
  header?: string
  // TODO-TS: signers seems like it's not being used anywhere. Should we remove it?
  signers?: Signers[]
}

export type Assignment = {
  id: string
  authRep?: string | null
  packet?: string
  packetPage: number | null
  packetStatus?: string
  tabs: Tab[]
  deedSubmissionAuditLogId: string | null
  executedDocumentId: string | null
  executedDocumentPage: number | null
  signatory: { id: string }
  signingGroupAssignment: SigningGroupAssignment | null
  itemId: string
  itemPageId: string | null
  requiresNotary: boolean
  status: string
  header?: string
  signers?: Signers[]
}

export type UpdatableAPIAssignment = Partial<
  Pick<
    APIAssignment,
    | "uuid"
    | "auth_rep"
    | "executed_document"
    | "executed_document_page"
    | "header"
    | "requires_notary"
  >
>

export const decode = (assignment: APIAssignment): Assignment => ({
  authRep: assignment.auth_rep,
  deedSubmissionAuditLogId: assignment.deed_submission_audit_log,
  executedDocumentId: assignment.executed_document,
  executedDocumentPage: assignment.executed_document_page,
  header: assignment.header,
  id: assignment.uuid,
  itemId: assignment.item,
  itemPageId: assignment.item_page_uuid,
  packet: assignment.packet,
  packetPage: assignment.packet_page,
  packetStatus: assignment.packet_status,
  requiresNotary: assignment.requires_notary,
  signatory:
    typeof assignment.signatory === "string"
      ? { id: assignment.signatory }
      : assignment.signatory,
  signingGroupAssignment: assignment.signing_group_assignment
    ? decodeSigningGroupAssignment(assignment.signing_group_assignment)
    : null,
  signers: assignment.signers,
  status: assignment.status,
  tabs: assignment.tabs.map((tab) => decodeTab(tab)),
})

export type UpdatableAssignment = Pick<Assignment, "id" | "requiresNotary">

export const encode = (
  assignment: UpdatableAssignment
): UpdatableAPIAssignment => ({
  requires_notary: assignment.requiresNotary,
  uuid: assignment.id,
})
