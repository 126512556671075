import { reduceIntoKeyByValue } from "helpers/array"
import { Signatory } from "models/Signatory"

function mapSignatoriesById(signatories: Signatory[]) {
  if (Array.isArray(signatories)) {
    return reduceIntoKeyByValue(signatories)
  }

  return signatories
}

export function findEntitiesForAuthRep(
  authRep?: Signatory,
  signatories?: Signatory[]
) {
  if (!authRep || !signatories) {
    return []
  }

  const signatoriesById = mapSignatoriesById(signatories)

  return authRep?.entityIds.map((entityId) => signatoriesById[entityId])
}

export function findRootEntitiesForAuthRep(
  authRep?: Signatory,
  signatories?: Signatory[],
  filter: (s?: Signatory) => boolean = () => false
): Signatory[] {
  const entities = findEntitiesForAuthRep(authRep, signatories)

  if (entities.length) {
    return entities.map((entity) => {
      const entityEntities = findRootEntitiesForAuthRep(
        entity,
        signatories,
        filter
      )
      if (entityEntities.length) {
        if (filter(entity)) {
          return [entity, ...entityEntities]
        }
        return entityEntities
      }
      return entity
    }) as Signatory[]
  }

  return []
}

export function addRootEntitiesToAuthRep(
  authRep: Signatory,
  signatories: Signatory[],
  filter: (entity?: Signatory) => boolean
) {
  return {
    ...authRep,
    entities: findRootEntitiesForAuthRep(authRep, signatories, filter),
  }
}
