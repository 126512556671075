import {
  type APISigningGroupMember,
  type SigningGroupMember,
  decode as decodeSigningGroupMember,
} from "./SigningGroupMember"

export type APISigningGroup = {
  uuid: string
  name: string
  transaction: string
  members: APISigningGroupMember[]
  created_at: string
}

export type SigningGroup = {
  id: string
  name: string
  transactionId: string
  members: string[]
  memberDetails: SigningGroupMember[]
  createdAt: string
}

export type CreatableAPISigningGroup = Partial<APISigningGroup> &
  Pick<APISigningGroup, "name" | "transaction">

export type CreatableSigningGroup = Partial<SigningGroup> &
  Pick<SigningGroup, "name" | "transactionId">

export type UpdatableAPISigningGroup = Pick<APISigningGroup, "uuid" | "name"> &
  Pick<Partial<APISigningGroup>, "transaction">

export type UpdatableSigningGroup = Pick<SigningGroup, "id" | "name"> &
  Pick<Partial<SigningGroup>, "transactionId">

export const decode = (group: APISigningGroup): SigningGroup => ({
  id: group.uuid,
  name: group.name,
  transactionId: group.transaction,
  members: group.members.map(({ signatory }) => signatory),
  memberDetails: group.members.map((member) =>
    decodeSigningGroupMember(member)
  ),
  createdAt: group.created_at,
})

export const encodeCreate = (
  group: CreatableSigningGroup
): CreatableAPISigningGroup => ({
  name: group.name,
  transaction: group.transactionId ?? "",
})

export const encodeUpdate = (
  group: UpdatableSigningGroup & { transactionId: string }
): UpdatableAPISigningGroup => ({
  name: group.name,
  uuid: group.id,
  transaction: group.transactionId,
})
