import { Checkbox, Button, Icon, Position } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"
import { ChangeEvent } from "react"

import { useUploadQueueState } from "../useUploadQueueState"
import { SigningType } from "models/Document"
import { countOfInProgressUploads } from "../utils"
import { useUploadQueueContext } from "../UploadQueueContext"
import { useTransactionIdParam } from "helpers/params"

type DocumentsQueueHeaderProps = {
  handleBulkActionSelection: (signingType: SigningType) => void
  handleToggleSelectAll: (evt: ChangeEvent<HTMLInputElement>) => void
  handleDeleteDocuments: () => void
}

export default function DocumentsQueueHeader({
  handleBulkActionSelection,
  handleToggleSelectAll,
  handleDeleteDocuments,
}: DocumentsQueueHeaderProps) {
  const { setDrawerIsOpen, isLoading } = useUploadQueueState()
  const transactionId = useTransactionIdParam()
  const { state } = useUploadQueueContext()

  const files =
    state[transactionId]?.files?.filter((f) => f.status !== "REMOVED") || []

  const selectedDocuments = files.filter((f) => f.isSelected) ?? []

  const hasInProgressUploads = !!countOfInProgressUploads(files)

  const filesCount = files.length

  const queuedUploadsCount = files.filter((f) => f.status === "QUEUED").length

  const allAreExecuted =
    (filesCount &&
      files.filter(({ signingType }) => signingType === "fully_executed")
        .length === filesCount) ||
    false

  const allAreNonExecutable =
    (filesCount &&
      files.filter(({ signingType }) => signingType === "non_executable")
        .length === filesCount) ||
    false

  const allAreExecutable =
    (filesCount &&
      files.filter(({ signingType }) => signingType === "to_be_signed")
        .length === filesCount) ||
    false

  const areAllFilesSelected =
    files.every((f) => f.isSelected) && files.length !== 0

  function handleSelection(evt: ChangeEvent<HTMLInputElement>) {
    let val = evt.currentTarget.value
    if (
      val === "non_executable" ||
      val === "to_be_signed" ||
      val === "fully_executed"
    ) {
      handleBulkActionSelection(val)
    }
  }

  const uploadProgressText = hasInProgressUploads
    ? `${
        filesCount - (countOfInProgressUploads(files) + queuedUploadsCount)
      } of ${filesCount} complete`
    : selectedDocuments.length > 0
      ? `${selectedDocuments.length} of ${filesCount} selected`
      : filesCount

  const checkboxWrapperClasses =
    "basis-0 grow flex flex-col text-center items-center justify-between md:flex-row md:justify-center md:text-left"

  return (
    <div className="w-full border-b grid p-4 z-1 grid-cols-[minmax(0,_1fr)_300px] lg:grid-cols-[minmax(0,_1fr)_500px] group">
      <div className="flex justify-start">
        <div className="flex justify-start items-center min-w-0">
          <Button
            icon={<Icon icon="chevron-down" size={20} />}
            minimal
            data-upload-queue-chevron
            onClick={() => setDrawerIsOpen(false)}
          />
          <Checkbox
            disabled={hasInProgressUploads}
            onChange={handleToggleSelectAll}
            checked={areAllFilesSelected}
            indeterminate={selectedDocuments.length > 0 && !areAllFilesSelected}
            className="my-0 ml-2"
            large
          />
          <h3 className="m-0 truncate text-sm font-semibold">
            Upload Queue ({uploadProgressText})
          </h3>
          {selectedDocuments.length > 0 ? (
            <Tooltip2
              content="Delete selected documents"
              position={Position.TOP}
              hoverOpenDelay={500}
            >
              <Button
                className="ml-2 group-hover:visible invisible"
                icon="trash"
                minimal
                onClick={handleDeleteDocuments}
                disabled={selectedDocuments.length <= 0 || isLoading}
              />
            </Tooltip2>
          ) : null}
        </div>
      </div>
      <div className="w-full inline-flex justify-around text-sm gap-2">
        <div className={checkboxWrapperClasses}>
          <Checkbox
            value="non_executable"
            checked={allAreNonExecutable}
            onChange={handleSelection}
            className="m-0 order-2 md:order-1"
          >
            <span className="order-1 md:order-2">Non-executable</span>
          </Checkbox>
        </div>
        <div className={checkboxWrapperClasses}>
          <Checkbox
            value="to_be_signed"
            checked={allAreExecutable}
            onChange={handleSelection}
            className="m-0 order-2 md:order-1"
          >
            <span className="order-1 md:order-2">To be signed</span>
          </Checkbox>
        </div>
        <div className={checkboxWrapperClasses}>
          <Checkbox
            value="fully_executed"
            checked={allAreExecuted}
            onChange={handleSelection}
            className="m-0 order-2 md:order-1"
          >
            <span className="order-1 md:order-2">Fully executed</span>
          </Checkbox>
        </div>
      </div>
    </div>
  )
}
