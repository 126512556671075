// @refresh reset
import * as React from "react"

import useToaster from "helpers/toaster"

import AuthPlaceholder from "features/dms/IM/AuthPlaceholder"
import IManageConfigSelect from "features/dms/IM/IManageConfigSelect"
import useIManageAuth from "features/dms/IM/useAuth"
import { withAuth } from "features/auth"
import { useUploadDocuments } from "features/dms/IM/api"
import DocumentTray from "components/add-documents/DocumentsQueue/DocumentTray"
import { Icon } from "@blueprintjs/core"

type IManageProps = {
  user?: Record<string, any>
  onClose?: () => void
  onUploaded?: () => void
  selection?: any
}

function IManage({
  onClose = () => null,
  onUploaded = () => null,
  selection = "multiple",
}: IManageProps) {
  const [selectedDocObjects, setSelectedDocObjects] = React.useState<
    Array<{ id: string; name: string }>
  >([])

  const isDocSelected = selectedDocObjects.length > 0

  let { config, status, data, reloadOAuthPopup } = useIManageAuth()
  let { uploadDocuments } = useUploadDocuments()
  let { failure } = useToaster()

  React.useEffect(() => {
    if (status === "ERROR") {
      failure("We ran into an issue attempting to authenticate with iManage")
    }
  }, [status])

  React.useEffect(() => {
    async function onIManageMessage(evt) {
      if (evt.data.type === "cancel") {
        onClose()
      }
      if (evt.data.type === "select") {
        let selectedDocs =
          selection === "single"
            ? [evt.data.data.output_data.selected[0]]
            : evt.data.data.output_data.selected

        let isAlreadySelected = selectedDocObjects.some(
          (doc) => doc.id === selectedDocs[0].id
        )

        if (!isAlreadySelected) {
          setSelectedDocObjects((prevDocs) => [
            ...prevDocs,
            { id: selectedDocs[0].id, name: selectedDocs[0].name },
          ])
          let { data: responseData, error } = await uploadDocuments(
            selectedDocs,
            data?.customerId,
            selection === "multiple"
          )
          if (error) {
            failure(
              "We ran into a problem attempting to add files to SimplyAgree"
            )
            return
          }

          onUploaded(responseData)
        }
      }
    }

    window.addEventListener("message", onIManageMessage, false)

    return () => window.removeEventListener("message", onIManageMessage, false)
  }, [data, selectedDocObjects])

  return (
    <div className="h-full w-full p-3">
      {status === "SELECT_CONFIG" ? (
        <div className="flex h-full w-full">
          <div className="m-auto">
            <IManageConfigSelect {...config} />
          </div>
        </div>
      ) : status === "NOT_AUTHENTICATED" ? (
        <AuthPlaceholder onTryAgain={reloadOAuthPopup} />
      ) : (
        <>
          {data && data.dialogToken && (
            <iframe
              src={`${config.currentConfig?.server_host}/work/partner-apps/${data.appId}/dialogs/file-picker/?protocol=postmessage&mode=open&dialogToken=${data.dialogToken}&types=document&customerId=${data.customerId}`}
              className="h-full w-full"
            />
          )}
          <>
            <DocumentTray
              title="Documents added to queue"
              collapsedTitle="SimplyAgree Documents"
              documentAmount={selectedDocObjects.length}
              isOpen={isDocSelected}
              onClose={onClose}
              collapsedPlacement="right-0 -bottom-16"
              placement="right-4 bottom-[4.25rem]"
            >
              <div>
                {selectedDocObjects.map((doc) => (
                  <div
                    key={doc.id}
                    className="mb-2 flex items-center rounded border border-gray-300 bg-white p-2"
                  >
                    <p className="overflow-hidden truncate">{doc.name}</p>
                    <Icon
                      icon="tick-circle"
                      className="ml-auto pl-1 text-blue-7"
                    />
                  </div>
                ))}
              </div>
            </DocumentTray>
          </>
        </>
      )}
    </div>
  )
}

const IMApp = withAuth(IManage)

export default IMApp
