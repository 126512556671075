type AuthPlaceholderProps = {
  onTryAgain?: () => void
}

export default function AuthPlaceholder({
  onTryAgain = () => null,
}: AuthPlaceholderProps) {
  return (
    <div className="flex h-full w-full">
      <div className="m-auto">
        <div className="mx-auto mb-5 w-52">
          <img src="/static/imanage.png" />
        </div>
        <div>
          <div className="text-center">
            Opening a pop-up window to log in with your firm&#39;s Single Sign
            On.
          </div>
          <div className="mt-6 rounded-lg border border-gray-300 bg-white px-4 py-3 text-center text-gray-600">
            <p className="mb-3">
              <span>If you do not see it momentarily,</span>
              <span className="font-bold">
                {" "}
                please make sure pop-ups are enabled{" "}
              </span>
              <span>on your browser for this website.</span>
            </p>
            <p className="mb-3">
              Here&#39;s how to enable pop-ups on common browsers:{" "}
              <a
                href="https://support.google.com/chrome/answer/95472?hl=en&amp;co=GENIE.Platform%3DDesktop&amp;oco=0"
                target="_blank"
                rel="noreferrer"
              >
                Google Chrome
              </a>
              <span className="mx-1">|</span>
              <a
                href="https://support.microsoft.com/en-us/microsoft-edge/block-pop-ups-in-microsoft-edge-516d558c-96ee-5ba4-8728-651aab1b983b"
                target="_blank"
                rel="noreferrer"
              >
                Microsoft Edge
              </a>
              <span className="mx-1">|</span>
              <a
                href="https://support.apple.com/guide/safari/websites-ibrwe2159f50/14.0/mac/11.0"
                target="_blank"
                rel="noreferrer"
              >
                Safari
              </a>
              <span className="mx-1">|</span>
              <a
                href="https://support.mozilla.org/en-US/kb/pop-blocker-settings-exceptions-troubleshooting#w_is-the-pop-up-blocker-on-and-enabled-for-this-site"
                target="_blank"
                rel="noreferrer"
              >
                Firefox
              </a>
            </p>
            <p className="mb-2">Once enabled, click here to try again</p>
            <p>
              <button className="bp4-button" onClick={onTryAgain}>
                Try again
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
