type EventTracker = NonNullable<typeof window.Appcues>

const NullTracker = {
  page() {},
  identify() {},
  show() {},
  track() {},
}

let eventTracker: EventTracker =
  typeof window === "undefined" ? NullTracker : window.Appcues || NullTracker

export function onSetEventTracker(tracker: EventTracker | undefined) {
  if (tracker) {
    eventTracker = tracker
  }
}

export default class UserEngagement {
  static onPageChange() {
    eventTracker.page()
  }

  static identifyUser(id: string, meta = {}) {
    eventTracker.identify(id, meta)
  }

  static show(id: string) {
    eventTracker.show(id)
  }

  static track(event: string, meta = {}) {
    eventTracker.track(event, meta)
  }
}
