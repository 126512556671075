import UploadIconViolet from "icons/upload-violet.svg"

export default function DocumentsQueueDragActiveState() {
  return (
    <div className="w-full absolute z-10 text-white h-full">
      <div className="flex-1 h-full flex flex-col items-center gap-4 justify-center w-full">
        <div className="h-16 w-16 flex items-center justify-center">
          <UploadIconViolet className="flex-shrink-0 " />
        </div>
        <h3 className="text-base tracking-normal font-semibold">
          Drop documents to begin uploading.
        </h3>
        {/* The leading-10 makes this h4 the same height as the buttons row in DocumentQueueDrawer 
                and prevents ui from moving slightly as the user drags files over the dropzone. */}
        <h4 className="leading-10">Supported file types: pdf, doc, docx</h4>
      </div>
    </div>
  )
}
