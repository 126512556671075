import * as React from "react"

import SAErrorPage from "features/error/ErrorPage"

import { ErrorClient } from "../error-reporting"

type DeNovoErrorBoundaryProps = {
  children?: React.ReactNode
}

export default class DeNovoErrorBoundary extends React.Component<
  DeNovoErrorBoundaryProps,
  { error: any }
> {
  constructor(props: DeNovoErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    ErrorClient.leaveBreadcrumb("error-boundary-info", { errorInfo })
    ErrorClient.notify(error)
  }

  render() {
    if (this.state.error) {
      return <SAErrorPage error={this.state.error} />
    }

    if (this.props.children) {
      return this.props.children
    }
  }
}
