import * as React from "react"

import { Icon, Intent } from "@blueprintjs/core"

type IManageConfigSelectProps = {
  availableConfigs?:
    | {
        id: string
        name: string
        oauth_client_id: string
        server_host: string
      }[]
    | null
  configId?: string | null
  setConfigId?: (id: string) => void
}

export default function IManageConfigSelect({
  configId,
  availableConfigs,
  setConfigId,
}: IManageConfigSelectProps) {
  function handleSelected(id) {
    setConfigId(id)
  }

  return (
    <div className="space-y-3">
      <h4 className="text-xl tracking-wide">Please choose an iManage server</h4>
      {availableConfigs.map((config) => (
        <div
          className="flex w-full cursor-pointer rounded border border-blue-6 p-4 text-blue-6 transition-shadow hover:shadow-lg active:bg-gray-200 active:shadow-none"
          key={config.id}
          onClick={() => handleSelected(config.id)}
        >
          <img className="mr-3 w-12 flex-shrink-0" src="/static/imanage.png" />
          <div>
            <h4 className="mb-px font-bold">{config.name}</h4>
            <p className="text-md">{config.server_host}</p>
          </div>
          {configId === config.id && (
            <Icon
              className="my-auto ml-auto"
              icon="tick-circle"
              size={28}
              intent={Intent.SUCCESS}
            />
          )}
        </div>
      ))}
    </div>
  )
}
